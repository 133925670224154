import React, { useContext } from 'react'
import GenericBanner from '../GenericBanner'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import styles from './styles/FullWidthBanner.module.scss'

const FullWidthBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null

  return (
    <GenericBanner
      crop="FULL_WIDTH_BANNER_L"
      className={styles.fullWidthBanner}
      lazy
      termsAndConditionsClassName={styles.fullWidthBannerTermsConditions}
      videoWrapperClassName={styles.fullWidthBannerVideoWrapper}
    />
  )
}

export default FullWidthBanner
