import React from 'react'
import { CmsProductTile } from '@components/Cms/CmsComponents-CSS/CmsProductTile'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMExternalProduct } from '@typesApp/cmsPlacement/CMExternalProduct'
import { ICMProductList } from '@typesApp/cmsPlacement/CMProductList'
import { ICMProductTeaser } from '@typesApp/cmsPlacement/CMProductTeaser'
import { StyledGridOfProductsContainer, StyledGridOfProductsItem } from './GridOfProducts.style'

export interface IProps {
  placement: IPlacement | ICMCollection | ICMProductList
}
type IProductType = (ICMExternalProduct | ICMProductTeaser)[]

const GridOfProducts: React.FC<IProps> = ({ placement }) => {
  let productData
  if ('items' in placement) {
    const items =
      (placement.items.filter(item =>
        ['CMProductTeaser', 'CMExternalProduct', 'CMProductlist'].includes(item.type)
      ) as IProductType) || []

    productData = items.map(x => x.productData) || []
  } else {
    if (placement.type === 'CMCollection') {
      const teasableItems =
        (placement.teasableItems.filter(item =>
          ['CMProductTeaser', 'CMExternalProduct', 'CMProductlist'].includes(item.type)
        ) as IProductType) || []

      productData = teasableItems.map(x => x.productData) || []
    } else {
      productData = placement.productData || []
    }
  }

  return (
    <StyledGridOfProductsContainer>
      {productData.map(
        (teaser, index) =>
          teaser && (
            <StyledGridOfProductsItem key={teaser.uniqueID} gridOfProducts>
              <CmsProductTile product={teaser} hidePrice />
            </StyledGridOfProductsItem>
          )
      )}
    </StyledGridOfProductsContainer>
  )
}

export default GridOfProducts
