import React from 'react'
import clsx from 'clsx'
import styles from './styles/CmsTeaserDetailText.module.scss'

type CmsTeaserDetailTextProps = {
  className?: string
  dataCmMetadata?: string
  teaserTextValue: string
}

export const CmsTeaserDetailText: React.FC<CmsTeaserDetailTextProps> = ({
  className,
  dataCmMetadata,
  teaserTextValue,
}) => {
  return (
    <div
      className={clsx(className, styles.container)}
      data-cm-metadata={dataCmMetadata}
      dangerouslySetInnerHTML={{ __html: teaserTextValue }}
    />
  )
}
