import React, { useEffect, useRef, useState } from 'react'
import { ITableArticle } from '@typesApp/cmsPlacement/Placement'
import { StyledTableModuleWrapper, StyledTitle, TableContainer, StyledTableModuleContainer } from '../TableModule.style'
import config from '../../../../configs'
import useIsInViewport from '../../../../hooks/useIsInViewport'

const TableModuleArticle: React.FC<{ item: ITableArticle }> = ({ item }) => {
  const title = item.title || ''
  const detailText = item.detailText || ''
  const totalColumns = (detailText.split('<td>').length - 1) / (detailText.split('<tr>').length - 1)
  const smallViewWidth = totalColumns > 2 ? `${totalColumns * 40}%` : '100%'

  const [parsedDetailText, setParsedDetailText] = useState<string>(detailText)
  const tableRef = useRef<HTMLDivElement>(null)
  const isTableInView = useIsInViewport(tableRef, {})

  useEffect(() => {
    if (parsedDetailText) {
      const re = new RegExp('media://|{mediaCmsUrl}', 'g')

      if (re.test(parsedDetailText)) {
        const newTeaserText = parsedDetailText.replace(re, config.cmsImageServerUrl)
        setParsedDetailText(newTeaserText)
      }
    }
  }, [item])

  useEffect(() => {
    const tableEl = tableRef?.current
    if (tableEl) {
      tableEl.querySelectorAll<HTMLImageElement>('img[data-src]').forEach(img => {
        if (img.dataset.src) {
          img.src = img.dataset.src
        }
      })
    }
  }, [isTableInView])

  return (
    <StyledTableModuleWrapper istextoverlay={0} ref={tableRef}>
      <StyledTableModuleContainer>
        {title && (
          <StyledTitle data-cm-metadata={`[{"_":"properties.${title}"}]`} textAlign={'center'}>
            {title}
          </StyledTitle>
        )}
        {detailText && (
          <TableContainer smallViewWidth={smallViewWidth} dangerouslySetInnerHTML={{ __html: parsedDetailText }} />
        )}
      </StyledTableModuleContainer>
    </StyledTableModuleWrapper>
  )
}

export default TableModuleArticle
