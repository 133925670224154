import { Accordion, AccordionSummary } from '../../UI'

import styled from '@mui/material/styles/styled'

export const WrapperFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Wrapper',
})(({ theme }) => ({
  ul: {
    paddingLeft: theme.spacing(5), // see: https://abstractsrl.atlassian.net/browse/DCW-4527
    ['& ::marker']: {
      fontSize: '0.5rem',
    },
  },

  color: theme.palette.text.dark.primary,
  padding: `${theme.spacing(2)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(24)} 0 ${theme.spacing(24)}`,
  },

  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(44)} 0 ${theme.spacing(44)}`,
  },
}))

export const TitleFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Title',
})(() => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  textAlign: 'center',
}))

export const ContentFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Content',
})(() => ({}))

export const StyledFaqLink = styled('div', {
  name: 'Faqs',
  slot: 'StyledFaqLink',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.background.dark.primary}`,
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: 1.5,
  minHeight: 0,
  padding: `0 0 ${theme.spacing(1)} 0`,
  
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(11)} 0 ${theme.spacing(5)} 0`,
  },
}))

export const StyledAccordion = styled(Accordion, {
  name: 'Faqs',
  slot: 'Accordion',
})(({ theme }) => ({
  '&.MuiAccordion-root': {
    borderBottom: `1px solid ${theme.palette.background.dark.primary}`,
  },
}))
export const StyledAccordionSummary = styled(AccordionSummary, {
  name: 'Faqs',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  borderBottom: 'none',
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: 1.5,
  minHeight: 0,
  margin: `${theme.spacing(7)} 0 ${theme.spacing(5)} 0`,

  '& .MuiAccordionSummary-content': {
    padding: `${theme.spacing(1)} 0`,
  },

  [theme.breakpoints.up('sm')]: {
    margin: `${theme.spacing(11)} 0 ${theme.spacing(5)} 0`,
  },
}))

export const AccordionDetails = styled('div', {
  name: 'Faqs',
  slot: 'AccordionDetails',
})(({ theme }) => ({
  fontSize: '1rem',
  p: {
    margin: `0 0 ${theme.spacing(4)} 0`,
  },
}))
