import React from 'react'
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import { CmsModuleIcon } from '@components/Cms/CmsComponents-CSS/CmsModuleIcon/CmsModuleIcon'
import {
  SquareBoardWithoutSplitArticle,
  SquareBoardWithoutSplitCTAContainer,
  SquareBoardWithoutSplitPreTitle,
  SquareBoardWithoutSplitTextContent,
} from '../SquareBoardWithoutSplit.style'
import { CmsProductTile } from '../../../../CmsComponents-CSS/CmsProductTile'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { CmsTeaserDetailText } from '@components/Cms/CmsComponents-CSS/CmsTeaserDetailText/CmsTeaserDetailText'
import { CmsTeaserTitle } from '@components/Cms/CmsComponents-CSS/CmsTeaserTitle/CmsTeaserTitle'

export const SquareBoardWithoutSplitText: React.FC<{
  teaser?: ILXTeaser
  viewType?: IViewType
  teaserIndex?: number
  bannerIndex?: number
}> = ({ teaser, viewType, bannerIndex, teaserIndex }) => {
  const {
    teaserTitle,
    teaserText,
    teaserTitleValue,
    teaserTextValue,
    teaserOverlaySettingsValue,
    teaserOverlayTextAlignValue,
    teaserOverlayStyleValue,
    promoteToH1,
    teaserLXCallToActionSettings,
    teaserPreTitle,
    teaserIcon,
    productData,
    isFrameAdvisor,
  } = useCmsTeaserBanner({ item: teaser, viewType: viewType }) || {}

  const content = (
    <>
      {teaserIcon && <CmsModuleIcon teaserIcon={teaserIcon} />}
      <CmsCountdown teaser={teaser} />
      <>
        {teaserPreTitle && (
          <>
            <SquareBoardWithoutSplitPreTitle
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
            >
              {teaserPreTitle}
            </SquareBoardWithoutSplitPreTitle>
          </>
        )}
        {teaserTitleValue && (
          <CmsTeaserTitle dataCmMetadata={`[{"_":"properties.${teaserTitle}"}]`} variant={promoteToH1 ? 'h1' : 'h2'}>
            {teaserTitleValue}
          </CmsTeaserTitle>
        )}
        {teaserTextValue && (
          <CmsTeaserDetailText
            dataCmMetadata={`[{"_":"properties.${teaserText}"}]`}
            teaserTextValue={teaserTextValue}
          />
        )}
      </>
      <CmsProductTile product={productData} />
    </>
  )

  return (
    <>
      <SquareBoardWithoutSplitTextContent>
        <SquareBoardWithoutSplitArticle
          overlaysettings={teaserOverlaySettingsValue}
          overlaytxtalign={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
          teaseroverlaystyle={teaserOverlayStyleValue}
        >
          {content}
        </SquareBoardWithoutSplitArticle>
        {!!teaserLXCallToActionSettings && teaserLXCallToActionSettings?.length > 0 && (
          <SquareBoardWithoutSplitCTAContainer data-element-id={isFrameAdvisor && 'X_X_Footer_FrameGenius_Open'}>
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <CmsCta
                dataElementId={`X_X_${teaserIndex}Placement_Banner${bannerIndex}_CTA${index + 1}`}
                key={`cms-content__text-module--cta-${index + 1}`}
                teaserCtaSetting={actionSettings}
              />
            ))}
          </SquareBoardWithoutSplitCTAContainer>
        )}
      </SquareBoardWithoutSplitTextContent>
    </>
  )
}
