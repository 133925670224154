import { IProduct } from '../../types/product'

export interface SuggestedProductTileProps<T extends IProduct = any> {
  footerElementsDisplay?: {
    colors: boolean
    inWishlistAdded: boolean
  }
  product: T
  onClick?: () => void
  tileIndex: number
  variant?: string
  onWishlistButtonClick?: (productId: string) => void
  isClustered?: boolean
  isPDP?: boolean
  showPrice?: boolean
}

/**
 * TODO: cleanup this component
 * @deprecated
 */
const SuggestedProductTile = () => {
  return null
}

SuggestedProductTile.defaultProps = {
  isClustered: true,
}

export { SuggestedProductTile }
