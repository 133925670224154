import React from 'react'
import clsx from 'clsx'

import { GridItemType } from './types'
import styles from './styles/GridItem.module.scss'

const GridItem: React.FC<GridItemType> = ({ sm, md, lg, xl, xxl, className, children }) => {
  return (
    <div
      className={clsx(styles.gridItem, className, {
        [styles[`grid-sm-${sm}`]]: sm,
        [styles[`grid-md-${md}`]]: md,
        [styles[`grid-lg-${lg}`]]: lg,
        [styles[`grid-xl-${xl}`]]: xl,
        [styles[`grid-xxl-${xxl}`]]: xxl,
      })}
    >
      {children}
    </div>
  )
}

export default GridItem
