import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV4 } from '../../../../constants/ui'
import { TPlayerBannerHook } from '../../../../hooks/useBannerPlayer'
import { IconButton } from '@components/UI/IconButton'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const ControllerWrapper = styled('div', {
  name: 'CMSCommonMedia',
  slot: 'ControllerWrapper',
})(({ theme }) => ({
  position: 'absolute',
  top: 24,
  right: 24,
  borderRadius: 50,
  padding: theme.spacing(2, 4),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  backdropFilter: 'blur(16px)',
  backgroundColor: 'rgba(31, 31, 36, 0.4)',
  zIndex: Z_INDEX_LV4,
}))

const CmsVideoController: React.FC<{ playerBanner: TPlayerBannerHook }> = ({ playerBanner }) => {
  return (
    <ControllerWrapper>
      {playerBanner.isPlaying ? (
        <IconButton aria-label="Pause" onClick={() => playerBanner.setIsPlaying(prev => !prev)}>
          <SVGIcon library="media" name="pause" color="white" />
        </IconButton>
      ) : (
        <IconButton aria-label="Play" onClick={() => playerBanner.setIsPlaying(prev => !prev)}>
          <SVGIcon library="media" name="play" color="white" />
        </IconButton>
      )}
      {playerBanner.muted ? (
        <IconButton aria-label="Mute" onClick={() => playerBanner.setMuted(prev => !prev)}>
          <SVGIcon library="media" name="audio-off" color="white" />
        </IconButton>
      ) : (
        <IconButton aria-label="Unmute" onClick={() => playerBanner.setMuted(prev => !prev)}>
          <SVGIcon library="media" name="audio-on" color="white" />
        </IconButton>
      )}
    </ControllerWrapper>
  )
}

export default CmsVideoController
