import React from 'react'
import clsx from 'clsx'
import styles from './styles/CmsTeaserTitle.module.scss'

type CmsTeaserTitleProps = React.PropsWithChildren & {
  className?: string
  dataCmMetadata?: string
  variant?: 'h1' | 'h2' | 'h3'
}

export const CmsTeaserTitle: React.FC<CmsTeaserTitleProps> = ({ className, children, dataCmMetadata, variant }) => {
  const props = {
    className: clsx(className, styles.title),
    'data-cm-metadata': dataCmMetadata,
  }

  switch (variant) {
    case 'h1':
      return <h1 {...props}>{children}</h1>
    case 'h2':
      return <h2 {...props}>{children}</h2>
    case 'h3':
      return <h3 {...props}>{children}</h3>
    default:
      return <h2 {...props}>{children}</h2>
  }
}
