import React, { useContext } from 'react'
// components
import MediaOverlapV2 from '@components/CmsModules/MediaOverlapV2'
import BannerTextModule from '@components/CmsModules/TextModuleV2/BannerTextModule'
import {
  ContextWrapperData,
  PlacementContext,
} from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/types/PlacementContextWrapper.types'
import { TermsAndConditions } from '@components/Cms/CmsComponents-CSS/TermsAndConditions'
import GridContainer from '@components/UI-CSS/Grid/GridContainer'
import GridItem from '@components/UI-CSS/Grid/GridItem'
import { FETCH_PRIORITY_THRESHOLD, LAZY_LOAD_THRESHOLD } from '@constants/cms'
// types
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import styles from './styles/TwoColumnFullWidthBanner.module.scss'
export interface GridItemsProps {
  index: number
  placement: IPlacement<IPlacementItem>
  placementIndex: number
  teaser: IPlacementItem
}

const GridItems: React.FC<GridItemsProps> = ({ index, placement, placementIndex, teaser }) => {
  const isLazy = placementIndex >= LAZY_LOAD_THRESHOLD
  /* Performance: prefetch only first image */
  const isFetchPriority = index === 0 ? placementIndex <= FETCH_PRIORITY_THRESHOLD : false
  const newTeaser = { ...teaser, placementCounter: { placementIndex: placementIndex, teaserIndex: index } }

  return (
    <GridItem sm={12} md={6} className={styles.gridItem}>
      <div className={styles.wrapper}>
        <MediaOverlapV2
          isLazy={isLazy}
          isFetchPriority={isFetchPriority}
          crop="FULL_WIDTH_BANNER_DUAL"
          teaser={newTeaser as ILXTeaser}
          placement={placement}
        />
        <BannerTextModule
          placement={placement}
          teaserIndex={index}
          teaser={teaser as ILXTeaser}
          index={placementIndex}
        />
        <TermsAndConditions
          className={styles.termsAndConditions}
          dataElementId={`${placementIndex}Placement_Banner${index}`}
          teaser={teaser as ILXTeaser}
        />
      </div>
    </GridItem>
  )
}

const TwoColumnFullWidthBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context || !context.data) return null

  const { data } = context as ContextWrapperData<PlacementContextType>
  const placement = data.placement as IPlacement
  const placementIndex = data.index

  const renderGridItems = ({ index, teaser }) => (
    <GridItems key={index} index={index} placement={placement} teaser={teaser} placementIndex={placementIndex} />
  )

  return (
    <GridContainer className={styles.gridContainerNoGap}>
      {placement?.items?.map((teaser, index) => {
        if (teaser.type !== 'CMCollection') {
          return renderGridItems({ index, teaser })
        } else {
          const teasers = teaser.teasableItems as ILXTeaser[]

          return teasers.map((teaser, index) => {
            return renderGridItems({ index, teaser })
          })
        }
      })}
    </GridContainer>
  )
}

export default TwoColumnFullWidthBanner
