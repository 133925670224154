import { Tabs, TabsProps } from '@mui/material'
import styled from '@mui/material/styles/styled'
import React from 'react'

export const StyledTabsWithStyle = styled((props: TabsProps) => <Tabs centered {...props} />
)<{ parentbg }>(({ theme, parentbg }) => ({
  [theme.breakpoints.up('xs')]: {
    minHeight: theme.spacing(6),
    height: theme.spacing(6),
  },

  '& .MuiTabs-scroller': {
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: parentbg === 'light' ? theme.palette.custom.blue : theme.palette.custom.white
  },

  '& .MuiTabs-flexContainer': {
    justifyContent: 'center !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left !important'
    },
    width: '100%'
  },
  '& button': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'unset',
    fontSize: theme.spacing(4),
    lineHeight: theme.spacing(6),
    fontWeight: '400',
    minWidth: theme.spacing(32.5),
    whiteSpace: 'nowrap',
  },
  '& button:hover span': {
    borderBottom: `2px solid ${theme.palette.custom.light2.blue} !important`
  },
  '& button:hover': {
    color: `${parentbg === 'light' ? theme.palette.custom.black : theme.palette.custom.light2.blue} !important`
  }
}))

export const StyledTabsBestSellerWrapper = styled('div')(({ theme }) => ({
  width: 400,
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: 'unset',
    margin: 'unset'
  }
}))
