import React from 'react'
import clsx from 'clsx'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import { ContentTextModule, CtaContentTextModule, IconContentTextModule, PreTitleTextModule } from '../TextModule.style'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import TermsAndConditionsCta from '@components/CmsPlacement/TermsAndConditionsV2/TermsAndConditions'
import { CmsBroadContentWrapper } from '@components/Cms/CmsComponents-CSS/CmsBroadContentWrapper'
import { CmsTeaserDetailText } from '@components/Cms/CmsComponents-CSS/CmsTeaserDetailText/CmsTeaserDetailText'
import { CmsTeaserTitle } from '@components/Cms/CmsComponents-CSS/CmsTeaserTitle/CmsTeaserTitle'
import styles from '../styles/TextModuleArticle.module.scss'

const TextModuleTeaser: React.FC<{
  item: ILXTeaser
  viewType: IViewType
  teaserIndex?: number
  bannerIndex?: number
}> = ({ item, viewType, teaserIndex, bannerIndex }) => {
  const {
    teaserIcon,
    promoteToH1,
    teaserPreTitle,
    teaserTitleValue,
    teaserTitle,
    teaserOverlaySettingsValue,
    teaserText,
    teaserTextValue,
    teaserLXCallToActionSettings,
    teaserOverlayStyleValue,
    teaserOverlayTextAlignValue,
    isFrameAdvisor,
    itemHasTermsAndCondition,
  } = useCmsTeaserBanner({ item, viewType })

  const removePaddingTop = teaserTextValue.includes('text-small')
  const overlayTextAlign = cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)
  const overlayStyle = teaserOverlayStyleValue ?? ''

  return (
    <>
      <CmsBroadContentWrapper
        className={clsx(styles.boardContentWrapper, styles[overlayStyle], styles[overlayTextAlign])}
        overlaySettings={teaserOverlaySettingsValue}
      >
        <ContentTextModule removePaddingTop={removePaddingTop}>
          {teaserIcon && (
            <IconContentTextModule>
              <CmsIcon teaserIcon={teaserIcon} />
            </IconContentTextModule>
          )}
          {teaserPreTitle && (
            <PreTitleTextModule
              data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
              variant={promoteToH1 ? 'h1' : 'h2'}
              fontWeight={'600'}
              isUppercased
            >
              {teaserPreTitle}
            </PreTitleTextModule>
          )}
          {teaserTitleValue && (
            <CmsTeaserTitle
              className={styles.titleContainer}
              dataCmMetadata={`[{"_":"properties.${teaserTitle}"}]`}
              variant={promoteToH1 ? 'h1' : 'h2'}
            >
              {teaserTitleValue}
            </CmsTeaserTitle>
          )}
          {teaserTextValue && (
            <CmsTeaserDetailText
              dataCmMetadata={`[{"_":"properties.${teaserText}"}]`}
              teaserTextValue={teaserTextValue}
            />
          )}
          {teaserLXCallToActionSettings.length > 0 && (
            <CtaContentTextModule data-element-id={isFrameAdvisor && 'X_X_Footer_FrameGenius_Open'}>
              {teaserLXCallToActionSettings.map((actionSettings, index) => (
                <CmsCta
                  dataElementId={`X_X_${teaserIndex}Placement_Banner${bannerIndex}_CTA${index + 1}`}
                  key={`cms-content__text-module--cta-${index}`}
                  teaserCtaSetting={actionSettings}
                />
              ))}
            </CtaContentTextModule>
          )}
        </ContentTextModule>
      </CmsBroadContentWrapper>
      {itemHasTermsAndCondition && <TermsAndConditionsCta terms={item.targetsTermsAndConditions} />}
    </>
  )
}

export default TextModuleTeaser
