import React, { useState, useEffect } from 'react'
import { ModulesProps } from '../../../types/cms'
import {
  MediaOverlapContainer,
  MediaOverlapFullWidthContainer,
  // TODO: has a conflict with shoppable icon click on tablet and mobile
  // MediaOverlapAnchor,
  MediaOverlapLandscapeContainer,
  MediaOverlapTopPageContainer,
  MediaOverlapSquatContainer,
  MediaOverlapAnchor,
} from './MediaOverlap.style'
import { IPlacement, isLXTeaser, isVideoMedia } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { TMedia, IVideoMedia } from '@typesApp/cmsPlacement/Media'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import usePlayerBanner from '../../../hooks/useBannerPlayer'
import { ShoppableTeaser } from '@components/Cms/CmsComponents-CSS/ShoppableTeaser'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { CustomVideoController } from '../../../utils/bannerUtils'
import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import { getDataElementId } from '@utils/common'

export interface ImageOverlapProps extends ModulesProps {
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
  crop?: string
  isLazy?: boolean
  isFetchPriority?: boolean
}

export interface TeaserLinkProps {
  toLink: string
  isExternalLink?: boolean
}

const getMediaOverlapContainer = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'full-width-banner':
      return MediaOverlapFullWidthContainer
    case 'landscape-banner':
      return MediaOverlapLandscapeContainer
    case 'top-page-banner':
      return MediaOverlapTopPageContainer
    case 'squat-banner':
      return MediaOverlapSquatContainer
    default:
      return MediaOverlapContainer
  }
}

const MediaOverlap: React.FC<ImageOverlapProps> = ({
  isLazy = true,
  isFetchPriority = false,
  viewType,
  teaser,
  placement,
  teaserIndex,
  crop,
}) => {
  const placementCounter = teaser?.placementCounter || placement?.placementCounter
  const { basePath } = useStoreIdentity()
  const moduleTeaser = !!teaser
    ? teaser
    : 'items' in placement!
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)

  const { isMobile } = useBreakpoints()
  const [media, setMedia] = useState<TMedia>(moduleTeaser?.media?.[0] ?? ({} as TMedia))
  const [video, setVideo] = useState<IVideoMedia>()
  const playerBanner = usePlayerBanner(video)

  const getFormattedUrl = (): TeaserLinkProps => {
    let formattedUrl: TeaserLinkProps = { toLink: moduleTeaser ? `${basePath}${moduleTeaser.formattedUrl || ''}` : '' }
    if (!formattedUrl.toLink || formattedUrl.toLink === '') {
      const linkTarget = moduleTeaser?.teaserLXCallToActionSettings?.[0]?.target
      if (linkTarget) {
        const isExternalLink = linkTarget.type === 'CMExternalLink'
        formattedUrl = isExternalLink
          ? { toLink: linkTarget.url, isExternalLink: true }
          : { toLink: `${basePath}${linkTarget.formattedUrl || ''}` }
      }
    }

    return formattedUrl
  }

  const { toLink, isExternalLink } = getFormattedUrl()

  const viewtype = viewType || placement?.viewtype || 'default'
  const teaserName = teaser?.name ? `${teaser?.name.replaceAll(' ', '_')}` : ''
  const bannerImageCommonProps = {
    'aria-label': `Placement_${teaserName} IMG link. Teaser №${teaserIndex || 0}`,
    'data-element-id': getDataElementId(viewtype, placementCounter),
  }

  if (moduleTeaser?.type !== 'LXTeaser') {
    return null
  }

  const MediaOverlapWrapper = getMediaOverlapContainer(viewtype)

  const areItemsInPlacement = placement && 'items' in placement
  const teaserCms = areItemsInPlacement ? placement?.items[0] : placement?.teasableItems[0]
  const placementHotZones = (teaserCms as ILXTeaser)?.hotZones
  const isShoppableImage = (placementHotZones?.length && placementHotZones?.length >= 0) || false
  const isHoverOnBoardWithFieldsBelow =
    !isMobile && viewType === 'boards-with-fields-below' && moduleTeaser?.media?.length === 3 && video === undefined

  useEffect(() => {
    if (isVideoMedia(moduleTeaser?.media?.[0])) {
      setVideo(moduleTeaser?.media?.[0])
    }
    if (isMobile && moduleTeaser?.media?.[1] && video === undefined) {
      setMedia(moduleTeaser?.media?.[1])
    } else {
      setMedia(moduleTeaser?.media?.[0])
    }
  }, [])

  const onMediaMouseEnter = () => {
    if (isHoverOnBoardWithFieldsBelow) {
      setMedia(moduleTeaser?.media?.[2])
    }
  }

  const onMediaMouseLeave = () => {
    if (isHoverOnBoardWithFieldsBelow) {
      setMedia(moduleTeaser?.media?.[0])
    }
  }
  return (
    <MediaOverlapWrapper onMouseEnter={onMediaMouseEnter} onMouseLeave={onMediaMouseLeave}>
      {toLink ? (
        <MediaOverlapAnchor {...bannerImageCommonProps} external={isExternalLink} href={toLink}>
          {isShoppableImage && <ShoppableTeaser />}
          <CMSCommonMedia
            {...bannerImageCommonProps}
            isLazy={isLazy}
            isFetchPriority={isFetchPriority}
            type={crop || ''}
            media={media}
            playerBannerHook={playerBanner}
          />
        </MediaOverlapAnchor>
      ) : (
        <CMSCommonMedia
          {...bannerImageCommonProps}
          isLazy={isLazy}
          isFetchPriority={isFetchPriority}
          type={crop || ''}
          media={media}
          playerBannerHook={playerBanner}
        />
      )}
      {video && <CustomVideoController playerBanner={playerBanner} />}
    </MediaOverlapWrapper>
  )
}

export default MediaOverlap
