import React, { useMemo } from 'react'
import { useSearchParams } from 'next/navigation'

// components
import { PlacementContainer } from '@components/Cms/PlacementContainer'
import { PlacementContextWrapper } from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { PreLoader } from '../../UI'
// constants
import { DEFAULT_ACTIVE_PLACEMENTS } from './constants'
import { DEFAULT_VIEW_TYPE } from '../constants'
// types
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { IProduct } from '@typesApp/product'
// utils
import { getCmsModulesByPlacement, getDefaultViewPlacement, isPlacementVisible } from './placementHelpers'
import styles from './styles/PlacementsSwitch.module.scss'

/*
 * preLoadedPlacementCount - since placementsswitch is rendered separately, the index of placement is affected
 */
export interface PlacementsSwitchProps {
  activePlacements?: IViewType[]
  isPLP?: boolean
  loading?: boolean
  pdpData?: IProduct
  placements?: IPlacement[]
  preLoadedPlacementCount?: number
}

export const PlacementsSwitch: React.FC<PlacementsSwitchProps> = ({
  activePlacements = DEFAULT_ACTIVE_PLACEMENTS,
  loading,
  isPLP = false,
  pdpData,
  placements,
  preLoadedPlacementCount = 0,
}) => {
  const searchParams = useSearchParams()
  const activePlacementsWithDefault = [...activePlacements, DEFAULT_VIEW_TYPE] as IViewType[]
  const visiblePlacements = useMemo(() => {
    return placements
      ?.filter(placement => isPlacementVisible(placement.viewtype, activePlacementsWithDefault))
      .filter(placement => {
        return !isPLP ? placement : !searchParams?.get('offset') ? placement : placement?.name !== 'PLP_placement_1'
      })
      .map((placement, placementIndex) => {
        const index = placementIndex + preLoadedPlacementCount
        const modules = getCmsModulesByPlacement({
          activePlacements: activePlacementsWithDefault,
          index,
          isPLP,
          pdpData,
          placement,
        })

        if (modules !== null) {
          const initialData = {
            placement,
            teaserIndex: index,
            modules,
            index,
            pdpData,
          }

          // Default view type is special
          if (placement.viewtype === DEFAULT_VIEW_TYPE) {
            const firstItem = getDefaultViewPlacement(placement)
            if (
              !firstItem ||
              !(isPlacementVisible(firstItem?.viewtype, activePlacementsWithDefault) || firstItem?.type === 'CMHTML')
            ) {
              return null
            }
          }

          return (
            <PlacementContextWrapper key={`${placement.name}-${index}`} initialData={{ ...initialData }}>
              <PlacementContainer {...initialData} />
            </PlacementContextWrapper>
          )
        }
        return null
      })
  }, [JSON.stringify(placements)])

  return (
    <div className="home__main-container">
      {placements && placements.length > 0 && (
        <div className={styles.wrapper}>
          {loading ? (
            <div className={styles.loader}>
              <PreLoader size={50} />
            </div>
          ) : (
            <>{visiblePlacements}</>
          )}
        </div>
      )}
    </div>
  )
}
