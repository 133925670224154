import styled from '@mui/material/styles/styled'
import { ITeaserOverlayStyle, ITeaserOverlaySettings, ITeaserOverlayTextAlign } from '@typesApp/cmsPlacement/LXTeaser'
import { getRatioBanner, teaserOverlayStyleToColor } from '../../../../../utils/placements'
import { StyledAnchor } from '@components/UI/Anchor'
import { StyledTypography } from '@components/UI/Typography'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const SquareBoardWithoutSplitWrapper = styled('div', {
  name: 'SquareBoardWithoutSplit',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'order' && prop !== 'isComboMiniSlider',
})<{
  order?: string
  isComboMiniSlider?: boolean
}>(({ order, isComboMiniSlider, theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  flexWrap: 'wrap',
  a: {
    flexGrow: 0,
    whiteSpace: 'nowrap',
  },
  '& > div:nth-of-type(1)': {
    order: order,
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: isComboMiniSlider ? 'auto' : '1fr 1fr',
    '.lazy-load-wrapper img': {
      height: 'auto',
    },
  },
}))
export const SquareBoardWithoutSplitTextContent = styled('div', {
  name: 'SquareBoardWithoutSplit',
  slot: 'TextContent',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  bottom: 0,
  padding: theme.spacing(6),
  gap: theme.spacing(4),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(10),
  },
}))

export const SquareBoardWithoutSplitArticle = styled('article', {
  name: 'SquareBoardWithoutSplit',
  slot: 'Article',
  shouldForwardProp: prop => prop !== 'overlaysettings' && prop !== 'overlaytxtalign' && prop !== 'teaseroverlaystyle',
})<{
  overlaysettings?: ITeaserOverlaySettings
  overlaytxtalign?: ITeaserOverlayTextAlign
  teaseroverlaystyle?: ITeaserOverlayStyle
}>(({ theme, teaseroverlaystyle }) => ({
  '& > *': {
    textAlign: ' left',
    ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  },
}))

export const SquareBoardWithoutSplitPreTitle = styled(StyledTypography, {
  name: 'SquareBoardWithoutSplit',
  slot: 'PreTitle',
})(({ theme }) => ({
  fontSize: 16,
  paddingBottom: theme.spacing(),
}))

export const SquareBoardWithoutSplitCTAContainer = styled('div', {
  name: 'SquareBoardWithoutSplit',
  slot: 'CTAContainer',
})(({ theme }) => ({
  alignSelf: 'end',
  display: 'flex',
  flex: 1,
  width: '100%',
  justifyContent: 'start',
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'end',
  },
  '& > a': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
}))

export const SquareBoardWithoutSplitMediaOverlapContainer = styled('div', {
  name: 'SquareBoardWithoutSplitMediaOverlap',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'viewType',
})<{ viewType: IViewType }>(({ viewType, theme }) => ({
  width: '100%',
  position: 'relative',
  ...getRatioBanner(viewType, theme),
}))

export const SquareBoardWithoutSplitMediaOverlapAnchor = styled(StyledAnchor, {
  name: 'SquareBoardWithoutSplitMediaOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  height: '100%',
  fontSize: 0,
  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))

export const SquareBoardWithoutSplitItem = styled('div', {
  name: 'SquareBoardWithoutSplit',
  slot: 'Item',
})(() => ({
  overflow: 'hidden',
  position: 'relative',
}))
