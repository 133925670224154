import React from 'react'
import clsx from 'clsx'
import { ErrorMessageProps } from './types/ErrorMessage.types'
import styles from './styles/ErrorMessage.module.scss'

const ErrorMessage: React.FC<ErrorMessageProps> = ({ className, errorTitle, errorDescription }) => {
  return (
    <div className={clsx(className, styles.errorContainer, 'error-container')}>
      <span className={clsx(styles.errorTitle, 'error-title')}>{errorTitle}</span>
      {errorDescription && <span className={clsx(styles.errorDescription, 'error-desc')}>{errorDescription}</span>}
    </div>
  )
}

export default ErrorMessage
