import React from 'react'
import { StyledTabPanelWithStyle } from './StyledTabPanel.style'
import { StyledTabPanelProps } from '../../../types/tabPanel'
import { uniqueId } from '../../../utils/common'

export const StyledTabPanel = (props: StyledTabPanelProps) => {
  const { children, value, index, name, ...other } = props

  if (value === index) {
    return (
      <StyledTabPanelWithStyle
        role="tabpanel"
        hidden={value !== index}
        id={`${name}-tabpanel-${uniqueId(`${index}-`)}`}
        aria-labelledby={`${name}-tab-${uniqueId(`${index}-`)}`}
        {...other}
      >
        {children}
      </StyledTabPanelWithStyle>
    )
  } else {
    return <></>
  }
}
