import styled from '@mui/material/styles/styled'
import { Swiper } from 'swiper/react'
import { setTitleWOBFontSize } from '../../../utils/placements'
import { setCommonStyle } from '../../UI/Grid/GridContainer'

export const WrapperWallOfBrands = styled('div', {
  name: 'WallOfBrands',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  // display: 'grid',
  // gridTemplateColumns: 'repeat(6, 1fr)',
  justifyContent: 'center',
  padding: `${theme.spacing(6)} ${theme.spacing(10)}`,
  // rowGap: theme.spacing(6),
  // columnGap: theme.spacing(8),
  [theme.breakpoints.down(769)]: {
    padding: `${theme.spacing(6)} ${theme.spacing(5)}`
  },
  [theme.breakpoints.down(321)]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2)}`
  },
}))

export const WallOfBrandsTabItem = styled('div')(({ theme }) => ({
  width: '94vw',
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} 0`,
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: `${theme.spacing(8)} 0`,
  },
}))

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  '.swiper-pagination': {
    'margin-bottom': '34px !important',
  },
  '.swiper-pagination-bullet': {
    borderRadius: 0,
    height: 2,
    opacity: 1,
    background: theme.palette.custom.light2.grey,
    marginBottom: '-40px !important',
    '&.swiper-pagination-bullet-active': {
      background: theme.palette.custom.black,
    },
  }
}))

export const TitleWallOfBrands = styled('div', {
  name: 'WallOfBrands',
  slot: 'Title',
  shouldForwardProp: (props) => props !== 'isSingle'
})<{ isSingle: boolean }>(({ theme, isSingle }) => ({
  fontWeight: 700,
  fontSize: theme.spacing(6),
  lineHeight: theme.spacing(7.5),
  width: '100%',
  [theme.breakpoints.down(903)]: {
    ...setTitleWOBFontSize(theme),
  },
  [theme.breakpoints.down(isSingle ? 'xl' : 'sm')]: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(6)
  }
}))

export const StyledTabContainer = styled('div')(() => ({
  maxWidth: '260px',
  margin: 'auto'
}))

export const WallOfBrandsCtaContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(6),
}))

export const StyledGridContainer = styled('div', {
  name: 'WallOfBrandsContainer',
  slot: 'Grid',
  shouldForwardProp: (prop) => prop !== 'subcontainer' && prop !== 'gap' && prop !== 'container'
})<{
  subcontainer?: boolean,
  gap?: 0 | 1 | 4 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96,
  container?: boolean
}>(({ subcontainer, gap, container, theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateRows: '1fr',
  ...(setCommonStyle('xs', gap, container, subcontainer)),
  [theme.breakpoints.up('sm')]: {
    ...(setCommonStyle('sm', gap, container, subcontainer)),
  },
  [theme.breakpoints.up('md')]: {
    ...(setCommonStyle('md', gap, container, subcontainer)),
  },
  div: {
    display: 'flex'
  }
}))
