import React, { useRef, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
// types
import type { IProduct } from '@typesApp/product'
import type { SizeValuesType } from './SizeGuide.type'
// hooks
import useBreakpoints from '../../../../hooks/useBreakpoints'
// utils
import { getSize } from '../../../../utils/productAttributes'
// components
import * as S from './SizeGuide.style'
import { StyledSwitch } from '../../../../components/UI'
import { SizeGuideGlassesFrontal, SizeGuideGlassesThreeQuarters } from './SizeGuideGlasses'
import { useTranslation } from 'next-i18next'

type Props = {
  currentProduct: IProduct | undefined
}

SwiperCore.use([Pagination, Navigation])

const SizeGuide = React.forwardRef<HTMLDivElement, Props>(({ currentProduct }, ref) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()

  const paginationSwiperRef = useRef<HTMLDivElement>(null)

  const [swiper, setSwiper] = useState<SwiperCore>()
  const [activeSlider, setActiveSlider] = useState<number>(0)
  const [activeSwitch, setActiveSwitch] = useState<boolean>(false)
  const size = currentProduct ? getSize(currentProduct, t) : null

  const SHOW_FRONTAL_BUTTON = activeSlider === 0
  const SHOW_THREE_QUARTERS_BUTTON = activeSlider === 1

  const handleNextSlide = () => swiper?.slideNext()

  const handlePrevSlide = () => swiper?.slidePrev()

  const handleActiveSlider = (swiper: SwiperCore) => setActiveSlider(swiper.activeIndex)

  const mmToInches = (size: string) =>
    size && !isNaN(Number.parseFloat(size)) ? (Number.parseFloat(size) / 25.4).toFixed(2) : ''

  const size_values_mm: SizeValuesType = {
    lensWidth: currentProduct?.productAttributes['FRAME_SIZE'] || '',
    bridgeWidth: currentProduct?.productAttributes['BRIDGE_WIDTH'] || '',
    templeWidth: currentProduct?.productAttributes['TEMPLE_LENGTH'] || '',
    lensHeight: currentProduct?.productAttributes['LENS_HEIGHT'] || '',
    frameWidth: currentProduct?.productAttributes['HINGE_DISTANCE'] || '',
  }

  const size_values_inch: SizeValuesType = {
    lensWidth: mmToInches(size_values_mm.lensWidth),
    bridgeWidth: mmToInches(size_values_mm.bridgeWidth),
    templeWidth: mmToInches(size_values_mm.templeWidth),
    lensHeight: mmToInches(size_values_mm.lensHeight),
    frameWidth: mmToInches(size_values_mm.frameWidth),
  }

  return (
    <S.StyledWrapper ref={ref}>
      <h3 id="size-guide">{t('SizeGuide.Labels.SizeGuide').toUpperCase()}</h3>
      <S.StyledContainer>
        <S.StyledGuideBody>
          <h4>{t('SizeGuide.Labels.FrameSize').toUpperCase()}</h4>
          <S.StyledSizeData>
            {size} (
            {activeSwitch
              ? `${size_values_inch.lensWidth}/${size_values_inch.bridgeWidth}/${size_values_inch.templeWidth}/${size_values_inch.lensHeight}`
              : `${size_values_mm.lensWidth}/${size_values_mm.bridgeWidth}/${size_values_mm.templeWidth}/${size_values_mm.lensHeight}`}
            )
          </S.StyledSizeData>
          <S.StyledSizeData>{t('SizeGuide.Labels.FrameSizeDefinition')}</S.StyledSizeData>
          <S.StyledConverterContainer>
            <span>{t('SizeGuide.Labels.millimeters')}</span>
            <label>
              <StyledSwitch
                checked={activeSwitch}
                onChange={() => setActiveSwitch(!activeSwitch)}
                data-element-id="X_X_Prod_SizeFitGuidePanel"
                aria-label="X_X_Prod_SizeFitGuidePanel"
              />
            </label>
            <span>{t('SizeGuide.Labels.inches')}</span>
          </S.StyledConverterContainer>
          <S.StyledSizeHelper>
            {t('SizeGuide.Labels.FrameSizeReference')}{' '}
            {activeSwitch
              ? `${mmToInches('50')}/${mmToInches('22')}/${mmToInches('150')}/${mmToInches('50')}`
              : '50/22/150/50'}
            ).
          </S.StyledSizeHelper>
        </S.StyledGuideBody>

        <S.StyledSwiperContainer>
          <Swiper
            modules={[Pagination, Navigation]}
            pagination={{
              el: paginationSwiperRef.current,
              clickable: false,
              type: 'progressbar',
              renderProgressbar: className => `<div class="${className}"></div>`,
            }}
            onSwiper={setSwiper}
            onSlideChange={handleActiveSlider}
          >
            <SwiperSlide>
              <SizeGuideGlassesThreeQuarters sizeValues={activeSwitch ? size_values_inch : size_values_mm} />
            </SwiperSlide>
            <SwiperSlide>
              <SizeGuideGlassesFrontal sizeValues={activeSwitch ? size_values_inch : size_values_mm} />
            </SwiperSlide>
          </Swiper>
          {isMobile ? (
            <S.StyledProgressContainer secondSlide={activeSlider > 0 ? true : false}>
              <div />
            </S.StyledProgressContainer>
          ) : null}
        </S.StyledSwiperContainer>
        <S.StyledNavigationContainer>
          <S.StyledNavigationButtons
            onClick={handleNextSlide}
            shouldShowButton={SHOW_FRONTAL_BUTTON}
            data-element-id="X_X_Prod_SizeFitGuidePanel"
          >
            {t('SizeGuide.Labels.FrontalView')} {'>'}
          </S.StyledNavigationButtons>
          <S.StyledNavigationButtons
            onClick={handlePrevSlide}
            shouldShowButton={SHOW_THREE_QUARTERS_BUTTON}
            data-element-id="X_X_Prod_SizeFitGuidePanel"
          >
            {t('SizeGuide.Labels.ThreeQuartersView')} {'>'}
          </S.StyledNavigationButtons>
        </S.StyledNavigationContainer>
      </S.StyledContainer>
    </S.StyledWrapper>
  )
})

export default SizeGuide
