import styled from '@mui/material/styles/styled'
import { Pagination, PaginationItem } from '@mui/material'
import React from 'react'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const StyledPagination = styled(Pagination, {
  name: 'PaginationQueryList',
  slot: 'Pagination',
})(({ theme }) => ({
  '.MuiPaginationItem-root': {
    padding: 0,
    margin: 0,
  },

  '.MuiPaginationItem-root.Mui-selected': {
    backgroundColor: theme.palette.background.dark.secondary,
    color: theme.palette.color.black,
    fontWeight: 'bold',
  },

  '.MuiPagination-ul': {
    gap: theme.spacing(4),
  },

  '.MuiPaginationItem-root:hover': {
    backgroundColor: theme.palette.background.dark.quaternary,
  },

  '.MuiPaginationItem-previousNext:hover, .MuiPaginationItem-ellipsis:hover': {
    backgroundColor: 'transparent',
  },

  button: {
    fontSize: 10,
    fontWeight: 300,
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: '50%',
    color: theme.palette.text.light.secondary,
  },
}))

export interface IProps {
  page: number
  onChange: (_event: React.ChangeEvent<unknown>, value: number) => void
  count: number
}
const PaginationQueryList: React.FC<IProps> = ({ page, count, onChange }) => {
  const nextPag = page === count || count <= 4
  const prevPag = page === 1 || count <= 4

  return (
    <StyledPagination
      count={count}
      defaultPage={page}
      onChange={onChange}
      hidePrevButton={prevPag}
      hideNextButton={nextPag}
      renderItem={item => (
        <PaginationItem
          components={{
            previous: () => <SVGIcon library="arrow" name="arrow-left" />,
            next: () => <SVGIcon library="arrow" name="arrow-right" />,
          }}
          {...item}
        />
      )}
    />
  )
}
export default PaginationQueryList
