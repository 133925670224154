import React from 'react'
import clsx from 'clsx'
import styles from './styles/CmsModuleIcon.module.scss'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'

type CmsModuleIconProps = {
  isLandscape?: boolean
  teaserIcon?: string
}

export const CmsModuleIcon: React.FC<CmsModuleIconProps> = ({ isLandscape, teaserIcon }) => {
  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.icon, {
          [styles.landscape]: !!isLandscape,
        })}
      >
        {teaserIcon && <CmsIcon teaserIcon={teaserIcon} />}
      </div>
    </div>
  )
}
