import styled from '@mui/material/styles/styled'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import {
  teaserAlignToFlex,
  teaserOverlaySettingsToCSS,
} from '../../../utils/placements'
import { Typography } from '@mui/material'
import React from 'react'
import { Swiper } from 'swiper/react'

const StyledContainer = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledContainer',
  shouldForwardProp: (prop) => prop !== 'invertFlexDirection' && prop !== 'isMobile'
})<{ invertFlexDirection?: boolean, isMobile: boolean }>(({ invertFlexDirection, isMobile, theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  margin: '1%',
  flexDirection: invertFlexDirection ? 'row-reverse' : 'row',
  [theme.breakpoints.down(1024)]: {
    flexDirection: invertFlexDirection || isMobile ? 'column-reverse' : 'column',
    margin: 0,
  }
}))

const StyledArrowDiv = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyleArrowDiv',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  button: {
    ':after': {
      'content': 'none'
    },
    svg: {
      width: 24,
      height: 24,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4)
    }
  }
}))

const StyledRightArrowDiv = styled(StyledArrowDiv, {
  name: 'ComboMiniSlider',
  slot: 'StyleRightArrowDiv',
})(({ }) => ({
  justifyContent: 'flex-start'
}))

const StyledRight = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledRight',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  [theme.breakpoints.up(1024)]: {
    maxWidth: '50%',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(5)
  },
  '.custom-progressbar': {
    display: 'none'
  }
}))

const StyledLeft = styled(StyledRight, {
  name: 'ComboMiniSlider',
  slot: 'StyledLeft',
})(({ }) => ({
  span: {
    '> span': {
      display: 'flex !important',
      justifyContent: 'center'
    },
  }
}))

const CMSMediaContainer = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'CMSMediaContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(1024)]: {
    width: '98%',
    span: { width: '100%' }
  }
}))

const StyledContent = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledContent',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'start',
  width: '60%',
  [theme.breakpoints.down(1024)]: {
    width: '100%'
  },
  margin: 'auto 0'
}))

const ComboMiniSliderSwiper = styled(Swiper, {
  name: 'ComboMiniSlider',
  slot: 'Swiper',
})(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}))

const StyledTitle = styled(Typography, {
  name: 'ComboMiniSlider',
  slot: 'StyledTitle',
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.dark.primary,
  fontWeight: 700,
  lineHeight: 1.5,
  textAlign: 'center',
  marginTop: theme.spacing(4)
}))

const StyledSlider = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledSlider',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  maxWidth: '100%',
}))

const StyledCollectionInfoSection = styled(StyledSlider, {
  name: 'ComboMiniSlider',
  slot: 'StyledCollectionInfoSection ',
})(({ }) => ({
  width: '95%',
  flexDirection: 'column',
  marginTop: 0,
  '.cta-container': {
    width: '285px'
  }
}))

const StyledSlides = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledSlides',
})({
  display: 'flex',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '--webkit-overflow-scrolling': 'touch',
})

const StyledCTAContainer = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledCTAContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
  margin: '0 auto',
  padding: '0 45px',
  a: {
    width: '100%',
    height: theme.spacing(10),
    margin: 0,

  },
}))

const StyledCarouselContentContainer = styled('div', {
  name: 'ComboMiniSlider',
  slot: 'StyledCarouselContentContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: `${theme.spacing(4)} 0`,
  color: theme.palette.text.dark.primary,
  padding: 0,

  h3: {
    lineHeight: 1.5,
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'center',
    margin: `0 0 ${theme.spacing(2)} 0`
  },

  p: {
    lineHeight: 1.43,
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'center',
    margin: 0
  },
}))

const StyledRightContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'toggleMuted',
  name: 'ComboMiniSlider',
  slot: 'StyledRightContainer'
})(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  '> div': {
    gridTemplateColumns: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    'article > div': {
      marginTop: 0,
      a: {
        width: '100%'
      }
    }
  }
}))

const StyledBannerContentContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isVideo' &&
    prop !== 'overlaySettings' &&
    prop !== 'overlayTextAlign',
})<{
  overlaySettings: ILXTeaser['teaserOverlay1Settings']
  overlayTextAlign: React.CSSProperties['textAlign']
  isVideo?: boolean
}>(({ theme, overlaySettings, overlayTextAlign, isVideo }) => ({
  position: 'absolute',

  bottom:
    overlaySettings === 'block-left-bottom' && isVideo
      ? theme.spacing(16)
      : undefined,
  ...teaserOverlaySettingsToCSS(overlaySettings),

  display: 'flex',
  flexDirection: 'column',

  textAlign: overlayTextAlign,
  alignItems: teaserAlignToFlex(overlayTextAlign),

  gap: theme.spacing(8),

  p: {
    fontSize: theme.spacing(3.5),
    lineHeight: 1.43,
    margin: 0,
  },
}))

const StyledBannerContentTitle = styled(Typography, {
  name: 'ComboMiniSlider',
  slot: 'StyledBannerContentTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.custom.white,
}))

export {
  StyledContainer,
  StyledLeft,
  StyledRight,
  StyledContent,
  StyledTitle,
  StyledSlider,
  StyledSlides,
  StyledCTAContainer,
  StyledCarouselContentContainer,
  StyledRightContainer,
  StyledBannerContentContainer,
  StyledBannerContentTitle,
  ComboMiniSliderSwiper,
  CMSMediaContainer,
  StyledArrowDiv,
  StyledRightArrowDiv,
  StyledCollectionInfoSection
}
