import React from 'react'
import clsx from 'clsx'
import { ITeaserOverlaySettings } from '@typesApp/cmsPlacement/LXTeaser'
import styles from './styles/CmsBroadContentWrapper.module.scss'

type CmsBroadContentWrapperProps = {
  children: React.ReactNode
  className?: string
  isTextOverlay?: boolean
  overlaySettings?: ITeaserOverlaySettings
}

export const CmsBroadContentWrapper: React.FC<CmsBroadContentWrapperProps> = ({
  children,
  className,
  isTextOverlay,
  overlaySettings,
}) => {
  const teaserOverlay = overlaySettings as string

  return (
    <div
      className={clsx(className, styles.wrapper, {
        [styles[teaserOverlay]]: !!teaserOverlay,
        [styles.isTextOverlay]: !!isTextOverlay,
      })}
    >
      {children}
    </div>
  )
}
