import styled from '@mui/material/styles/styled'
import { getRatioBanner } from '../../../../../utils/placements'
import { StyledTypography } from '@components/UI/Typography'

export const ContentTextModule = styled('article', {
  name: 'TextModule',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'removePaddingTop',
})<{ removePaddingTop?: boolean }>(({ removePaddingTop, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(removePaddingTop ? 0 : 10, 4, 10),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 10, 24, 10),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 12, 44, 12),
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(removePaddingTop ? 0 : 16, 44, 16),
  },
}))

export const PreTitleTextModule = styled(StyledTypography, {
  name: 'TextModules',
  slot: 'PreTitle',
})(() => ({
  fontSize: 16,
}))

export const CtaContentTextModule = styled('div', {
  name: 'TextModules',
  slot: 'CtaContent',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: theme.spacing(2),
  justifyContent: 'inherit',
  gap: theme.spacing(4),
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const IconContentTextModule = styled('div', {
  name: 'TextModules',
  slot: 'IconContent',
})(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  svg: {
    height: 48,
    width: 48,
  },
}))

export const WrapperTextModuleMedia = styled('div', {
  name: 'WrapperTextModule',
  slot: 'Media',
})(({ theme }) => ({
  ...getRatioBanner('full-width-banner', theme),
}))
