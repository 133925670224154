import { MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY } from '@constants/order'

export const buildQueryParams = (storeId: string, partNumbers: string[]) => {
  const upcList = partNumbers?.map(partNumber => {
    return {
      UPC: partNumber ?? '',
      Quantity: MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY.toString(),
    }
  })

  return {
    storeId,
    body: {
      upcList,
    },
  }
}
