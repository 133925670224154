import styled from '@mui/material/styles/styled'

export const WrapperBrandIcon = styled('div', {
  name: 'BrandIcon',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}))

export const StyledTopPageTitle = styled<any>('h2', {
  name: 'TopSEOBlock',
  slot: 'Title',
  shouldForwardProp: (prop: string) => prop !== 'isLogo',
})(({ isLogo }) => ({
  textTransform: 'uppercase',
  fontSize: isLogo ? 9 : 20,
  textAlign: 'center',
}))

export const BrandImg = styled('img', {
  name: 'BrandIcon',
  slot: 'Image',
})(() => ({
  width: 'inherit',
  /*
  * Using filters to set the default image color as close to Neutral/Dark1 (#3D3C3C) as possible
  * See https://codepen.io/sosuke/pen/Pjoqqp
  */
	filter: 'invert(18%) sepia(9%) saturate(0%) hue-rotate(358deg) brightness(109%) contrast(86%)',
	transition: '.3s ease-in-out',

  '&:hover': {
    filter: 'none',
  }
}))
