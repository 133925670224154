import styled from '@mui/material/styles/styled'

export const ValuePropBannerWrapper = styled('div', {
  name: 'ValuePropBanner',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: 40,
  width: '100%',
  fontSize: theme.typography.subtitle1.fontSize,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  backgroundColor: theme.palette.background.light.quinary,
  color: theme.palette.text.dark.primary,
  fontWeight: '700',
  overflowX: 'auto',
  '&>:first-of-type': {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(6),
  },
  '&>:last-child': {
    marginRight: 'auto',
    paddingRight: theme.spacing(6),
  },
}))

export const ValuePropBannerItem = styled('div', {
  name: 'ValuePropBanner',
  slot: 'Item',
})(({ theme }) => ({
  marginRight: theme.spacing(6),
  whiteSpace: 'nowrap',
  lineHeight: '16px',
  position: 'relative',
  top: '-3px',
}))

export const ValuePropBannerItemIcon = styled('div', {
  name: 'ValuePropBanner',
  slot: 'ItemIcon',
})(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(1),
  position: 'relative',
  top: '3px',
}))
