import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { CmsCarousel } from '../CmsCarousel/CmsCarousel'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { useRecentlyViewedProducts } from '@foundation/hooks/useRecentlyViewedProducts'
import carouselTileStyles from './styles/RecentlyViewedCarouselTile.module.scss'

type RecentlyViewedCarouselProps = {
  title?: string
}

const RecentlyViewedCarousel: React.FC<RecentlyViewedCarouselProps> = ({ title }) => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const currentProduct = data?.pdpData ?? {}

  const { t: translate } = useTranslation()
  const carouselTitle = title ?? translate('ProductGrid.Recommendations.recentlyViewed')

  const { recentlyViewedProducts } = useRecentlyViewedProducts({ currentProduct })
  const [showCarousel, setShowCarousel] = useState<boolean>(true)

  const toggleCarousel = e => {
    const isVisible = Boolean(e.detail?.visible)
    setShowCarousel(!!isVisible)
  }

  /**
   * Custom event will be triggered via Monetate
   * to be used for A/B testing
   */
  useEffect(() => {
    window.addEventListener('monetate:toggleRecentlyViewed', toggleCarousel)

    return () => {
      window.removeEventListener('monetate:toggleRecentlyViewed', toggleCarousel)
    }
  }, [toggleCarousel])

  return (
    <>
      {showCarousel && recentlyViewedProducts?.length ? (
        <CmsCarousel
          products={recentlyViewedProducts}
          styleOverride={[undefined, carouselTileStyles]}
          title={carouselTitle}
        />
      ) : null}
    </>
  )
}

export default RecentlyViewedCarousel
