import React from 'react'
import {
  SquareBoardWithoutSplitItem,
  SquareBoardWithoutSplitWrapper,
} from './SquareBoardWithoutSplit.style'
import { SquareBoardWithoutSplitText } from './components/SquareBoardWithoutSplitText'
import SquareBoardWithoutSplitMediaOverlap from './components/SquareBoardWithoutSplitMediaOverlap'
import {
  IPlacement,
  isLXTeaser,
} from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'

export interface ISquareBoardWithOutSplitProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  reflect?: boolean
}

const SquareBoardWithoutSplit: React.FC<ISquareBoardWithOutSplitProps> = ({
  placement,
  reflect,
  teaserIndex,
}) => {
  const teasers =
    'items' in placement
      ? placement?.items.filter(isLXTeaser)
      : placement?.teasableItems.filter(isLXTeaser)
  const order: string = reflect ? '1' : '0'
  const isComboMiniSlider =
    placement?.viewtype === 'combo-mini-slider-plus-box-all-fields'

  return (
    <SquareBoardWithoutSplitWrapper
      order={order}
      isComboMiniSlider={isComboMiniSlider}
    >
      {teasers?.map((teaser, i) => (
        <SquareBoardWithoutSplitItem key={teaser.id}>
          <SquareBoardWithoutSplitMediaOverlap
            crop={
              isComboMiniSlider
                ? 'COMBO_MINI_SLIDER_BIG'
                : 'SQUARE_BOARDS_WITHOUT_SPLIT'
            }
            teaser={teaser}
            teaserIndex={teaserIndex}
            bannerIndex={i + 1}
            viewType={placement?.viewtype || 'default'}
          />
          <SquareBoardWithoutSplitText
            teaser={teaser}
            viewType={placement?.viewtype}
            teaserIndex={teaserIndex}
            bannerIndex={i + 1}
          />
        </SquareBoardWithoutSplitItem>
      ))}
    </SquareBoardWithoutSplitWrapper>
  )
}

export default SquareBoardWithoutSplit
