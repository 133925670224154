import React from 'react'
import TextModuleTeaser from './components/TextModuleTeaser'
import TextModuleArticle from './components/TextModuleArticle'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'

const TextModule: React.FC<{
  placement: IPlacement | ICMCollection
  teaserIndex?: number
}> = ({ placement, teaserIndex }) => {
  const item =
    'items' in placement ? placement.items[0] : placement.teasableItems[0]

  switch (item?.type) {
    case 'LXTeaser':
      return (
        <TextModuleTeaser
          item={item}
          viewType={placement.viewtype}
          teaserIndex={teaserIndex}
          bannerIndex={1}
        />
      )
    case 'CMArticle':
      return <TextModuleArticle item={item} />
    default:
      return null
  }
}

export default TextModule
