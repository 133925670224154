import { Button } from '@mui/material'
import styled from '@mui/material/styles/styled'

const StyledWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  background: theme.palette.custom.grey,
  color: theme.palette.custom.white,

  h3: {
    fontSize: theme.spacing(5),
    fontWeight: 600,
    lineHeight: 1.4,
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },

  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledContainer = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'normal',
  lineHeight: 1.43,

  [theme.breakpoints.up('lg')]: {
    display: 'grid',
    gridTemplateColumns: '25% 60% 1fr',
    gap: theme.spacing(4),
    maxWidth: '1450px',
    padding: `43px ${theme.spacing(22)} 69px ${theme.spacing(16)}`,
  },
}))

const StyledGuideBody = styled('div')(({ theme }) => ({
  width: '100%',

  h4: {
    fontSize: theme.spacing(4),
    fontWeight: 600,
    lineHeight: 1.5,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
}))

const StyledSizeData = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

const StyledConverterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '11px',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}))

const StyledSizeHelper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledProgressContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'secondSlide',
  name: 'SizeGuide',
  slot: 'ProgressContainer',
})<{ secondSlide: boolean }>(({ theme, secondSlide }) => ({
  height: 2,
  width: '50%',
  margin: `${theme.spacing(8)} auto`,
  backgroundColor: theme.palette.custom.boulder,

  div: {
    height: 2,
    backgroundColor: theme.palette.background.dark.secondary,
    width: secondSlide ? '100%' : '50%',
    transition: 'width .25s',
  },
}))

const StyledSwiperContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(8),
}))

const StyledNavigationContainer = styled('div')({
  position: 'relative',
})

const StyledNavigationButtons = styled(Button, {
  shouldForwardProp: props => props !== 'shouldShowButton',
})<{
  shouldShowButton?: boolean
}>(({ theme, shouldShowButton }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 600,
  lineHeight: 1.43,
  color: theme.palette.custom.white,
  textTransform: 'uppercase',
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: shouldShowButton ? 'flex' : 'none',
  },
}))

export {
  StyledWrapper,
  StyledContainer,
  StyledGuideBody,
  StyledSizeData,
  StyledConverterContainer,
  StyledSizeHelper,
  StyledSwiperContainer,
  StyledProgressContainer,
  StyledNavigationContainer,
  StyledNavigationButtons,
}
