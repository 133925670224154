import React from 'react'
import { IVideoMedia } from '../../../../../../types/cmsPlacement/Media'
import usePlayerBanner from '../../../../../../hooks/useBannerPlayer'
import CMSCommonMedia from '../../../../CmsComponents/CmsCommonMedia'
import { useStoreIdentity } from '../../../../../../foundation/hooks/useStoreIdentity'

import {
  SquareBoardWithoutSplitMediaOverlapAnchor,
  SquareBoardWithoutSplitMediaOverlapContainer,
} from '../SquareBoardWithoutSplit.style'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import { ImageOverlapProps } from '@components/Cms/CmsPlacement/CmsBanner/CMBanner.type'
import CmsVideoController from '@components/Cms/CmsComponents/CmsCommonMedia/CmsVideoController'
import { CRITICAL_PLACEMENT_LIMIT, FETCH_PRIORITY_THRESHOLD, LAZY_LOAD_THRESHOLD } from '@constants/cms'
import { ShoppableTeaser } from '@components/Cms/CmsComponents-CSS/ShoppableTeaser'
import styles from '../styles/SquareBoardWithoutSplit.module.scss'

const SquareBoardWithoutSplitMediaOverlap: React.FC<ImageOverlapProps> = ({
  viewType,
  teaser,
  crop,
  children,
  bannerIndex,
  teaserIndex,
}) => {
  const { basePath } = useStoreIdentity()

  const { isShoppableImage, media, isVideo, toLink } =
    useCmsTeaserBanner({
      item: teaser,
      viewType,
    }) || {}

  const playerBanner = usePlayerBanner(media! as IVideoMedia)

  const bannerImageCommonProps = {
    'aria-label': `Placement_Banner${bannerIndex}_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `X_X_${teaserIndex}Placement_Banner${bannerIndex}_IMG`,
  }

  return (
    <SquareBoardWithoutSplitMediaOverlapContainer viewType={viewType!}>
      {children}
      {toLink ? (
        <SquareBoardWithoutSplitMediaOverlapAnchor {...bannerImageCommonProps} href={`${basePath}${toLink}`}>
          <CMSCommonMedia
            isLazy={(teaserIndex ?? 0) >= LAZY_LOAD_THRESHOLD}
            isFetchPriority={(teaserIndex ?? 0) <= FETCH_PRIORITY_THRESHOLD}
            type={crop || ''}
            media={media}
            playerBannerHook={playerBanner}
          />
        </SquareBoardWithoutSplitMediaOverlapAnchor>
      ) : (
        <CMSCommonMedia
          isLazy={(teaserIndex ?? 0) > CRITICAL_PLACEMENT_LIMIT}
          isFetchPriority={(teaserIndex ?? 0) < CRITICAL_PLACEMENT_LIMIT}
          type={crop || ''}
          media={media}
          playerBannerHook={playerBanner}
        />
      )}
      {isShoppableImage && <ShoppableTeaser className={styles.shoppableContainer} />}
      {isVideo && <CmsVideoController playerBanner={playerBanner} />}
    </SquareBoardWithoutSplitMediaOverlapContainer>
  )
}

export default SquareBoardWithoutSplitMediaOverlap
