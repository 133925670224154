import styled from '@mui/material/styles/styled'
// import { themeModeSelector } from '../../../redux/selectors/theme';

export const StyledBoardWithIconsContainer = styled('div', {
  name: 'BoardWithIconsContainer',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'textColor' && prop !== 'iconCount',
})<{ backgroundColor, iconCount, textColor }>(({ backgroundColor, iconCount, textColor, theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  backgroundColor: backgroundColor,
  color: textColor,
  paddingTop: iconCount,

  [theme.breakpoints.up('md')]: {

  }
}))

export const BoardWithIconTitleContainer = styled('div', {
  name: ' BoardWithIconTitle',
  slot: 'Title',
})(({ theme }) => ({
  marginTop: theme.spacing(18),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  fontSize: '24px',
  textAlign: 'center',
  fontWeight: 'bold',

  [theme.breakpoints.down(376)]: {
    marginTop: theme.spacing(4)
  }
}))

export const BoardWithIconTextContainer = styled('div', {
  name: ' BoardWithIconTitle',
  slot: 'TextContainer',
})(({ theme }) => ({
  marginTop: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  fontSize: '16px',
  textAlign: 'center',

  [theme.breakpoints.down(376)]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  }
}))

export const BoardWithIconLinkContainer = styled('div', {
  name: ' BoardWithIconLink',
  slot: 'Title',
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'textColor' && prop !== 'iconCount',
})<{ backgroundColor, iconCount, textColor }>(({ iconCount }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '16px', // To give some 16px distance to the text
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  fontSize: '16px',
  textAlign: 'center',
  textDecoration: 'underline',
  color: iconCount === 4 ? '#1C4DA1' : '#C7E4F8',
  fontWeight: iconCount === 4 ? 'bold' : 'normal',
  cursor: 'pointer',
  'a': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px'
  }
}))

export const StyledBoardWithIconsItemContainer = styled('div', {
  name: 'BoardWithIcons',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'textColor' && prop !== 'iconCount',
})<{ backgroundColor, iconCount, textColor }>(({ iconCount, theme }) => ({
  marginTop: theme.spacing(10),
  display: 'grid',
  gridTemplateColumns: `repeat(${iconCount}, 1fr)`,
  marginBottom: theme.spacing(16),

  // Medium screen
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(${iconCount}, 1fr)`,
    gridColumnGap: theme.spacing(8),
    gridRowGap: theme.spacing(8)
  },

  [theme.breakpoints.down(769)]: {
    marginBottom: theme.spacing(10),
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(8),
    gridRowGap: theme.spacing(8),
    marginTop: theme.spacing(4)
  }
}))

export const StyledBoardWithIconsItem = styled('div', {
  name: 'BoardWithIcons',
  slot: 'Item',
})(({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '5px'
}))
