import React from 'react'
import SuggestedProducts from '@views/ProductDetails/components/SuggestedProducts'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import { StyledSuggestionContainer } from './DcwProducts.style'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMProductTeaser } from '@typesApp/cmsPlacement/CMProductTeaser'
import { ICMExternalProduct } from '@typesApp/cmsPlacement/CMExternalProduct'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMProductList } from '@typesApp/cmsPlacement/CMProductList'

export interface IProps {
  placement: IPlacement | ICMCollection | ICMProductList
  teaserIndex?: number
}
type IProductType = (ICMExternalProduct | ICMProductTeaser)[]

const DcwProducts: React.FC<IProps> = ({ placement }) => {
  const { isDesktop } = useBreakpoints()
  let isImgWithShadow = false
  let productData
  if ('items' in placement) {
    const items =
      (placement.items.filter((item) =>
        ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)
      ) as IProductType) || []

    productData = items.map((x) => x.productData) || []
  } else {
    if (placement.type === 'CMCollection') {
      isImgWithShadow = placement.collectionProductStyle === 'front-quart-shad'
      const teasableItems =
        (placement.teasableItems.filter((item) =>
          ['CMProductTeaser', 'CMExternalProduct'].includes(item.type)
        ) as IProductType) || []

      productData = teasableItems.map((x) => x.productData) || []
    } else {
      productData = placement.productData || []
    }
  }

  return (
    <StyledSuggestionContainer>
      <SuggestedProducts products={productData} showNavigation={isDesktop} />
    </StyledSuggestionContainer>
  )
}

export default DcwProducts
