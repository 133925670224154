import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV2 } from '../../../constants/ui'

export const SquareBoardsContainer = styled('div', {
  name: 'SquareBoards',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'split',
})<{ split?: boolean; order?: string }>(({ theme, split, order }) => ({
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gap: split ? theme.spacing(8) : 0,
  flexDirection: 'column',
  flexWrap: 'wrap',
  a: {
    flexGrow: 0,
    whiteSpace: 'nowrap',
  },
  '& > div:nth-of-type(1)': {
    order: order
  },
  [theme.breakpoints.up(769)]: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: split ? theme.spacing(8) : 0,
    padding: 0,
  },
}))

export const SquareBoardsItem = styled('div',{
  name: 'SquareBoards',
  slot: 'Item',
})(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    paddingTop: 0,
  },
}))

export const SquareBoardsTextWrapper = styled('div',{
  name: 'SquareBoards',
  slot: 'TextWrapper',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'absolute',
  bottom: '6px',
  width: '100%',
  'a': {
    margin: '16px 0',
  },
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
  },
}))

export const SquareBoardsMediaWrapper = styled('div',{
  name: 'SquareBoards',
  slot: 'MediaWrapper',
})(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  'span, a, >div' : {
    height: 'inherit'
  },
  [theme.breakpoints.up('md')]: {
    position: 'relative',
  },
}))
