import { useEffect, useState } from 'react'
import { ITEM_DETAILS_PROFILE_NAME, PROFILE_NAME_PARAM } from '@constants/common'
import { RECENTLY_VIEWED_PRODUCT_HISTORY } from '@constants/product'
import { useGetProductDetailsQuery } from '@features/product/query'
import { useSite } from '../useSite'
import { RecentlyViewedProduct } from './types/useRecentlyViewedProducts.types'
import { getModelName, getProductType } from '@utils/productAttributes'

const MAX_PRODUCTS_IN_CAROUSEL = 12

export const useRecentlyViewedProducts = ({ currentProduct }) => {
  const { mySite } = useSite()
  const [recentlyViewedProductIds, setRecentlyViewedProductIds] = useState<string[]>([])

  const { data: productDetails } = useGetProductDetailsQuery(
    {
      queryParams: {
        id: recentlyViewedProductIds,
        storeId: mySite.storeID,
        [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !recentlyViewedProductIds?.length,
    }
  )

  const currentPartNumber = currentProduct?.partNumber
  let recentlyViewedProducts = !productDetails
    ? []
    : recentlyViewedProductIds?.length === 1
      ? [productDetails]
      : productDetails

  if (recentlyViewedProducts?.length) {
    recentlyViewedProducts = recentlyViewedProducts
      ?.slice()
      ?.sort((a, b) => {
        const aValue = a.id ?? ''
        const bValue = b.id ?? ''
        return recentlyViewedProductIds.indexOf(aValue) - recentlyViewedProductIds.indexOf(bValue)
      })
      ?.filter((value, index, self) => {
        // if user is on a pdp, then don't display the same product in the carousel
        if (currentProduct.cluster?.find(item => item['relationship.item.id'] === value.id)) return false

        const isCL = getProductType(value) === 'Contact Lenses'
        const parentProductId = value.parentCatalogEntryID
        const model = getModelName(value)

        const uniqueParent =
          self.findIndex(
            v => (parentProductId && v.parentCatalogEntryID === parentProductId) || getModelName(v) === model
          ) === index

        return isCL || uniqueParent
      })
      ?.slice(0, MAX_PRODUCTS_IN_CAROUSEL)
  }

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const pdpViewHistory = localStorage.getItem(RECENTLY_VIEWED_PRODUCT_HISTORY)
      let products = (pdpViewHistory && JSON.parse(pdpViewHistory).products) ?? []

      /**
       * NOTE:
       * the history tracker lists the products from first (oldest) to last (recent) viewed,
       * so we just need to reverse it to get the most recently viewed items at the top.
       */
      products.reverse()

      if (currentPartNumber) {
        products = products.filter((product: RecentlyViewedProduct) => product.upc !== currentPartNumber)
      }

      const productIdList: string[] = products.map((product: RecentlyViewedProduct) => product.sku)
      setRecentlyViewedProductIds([...new Set(productIdList)])
    }
  }, [])

  return {
    recentlyViewedProducts,
  }
}
