import React from 'react'
import clsx from 'clsx'
import { replaceTextMediaCmsUrl } from '../../../../../../utils/placements'
import { ContentTextModule, WrapperTextModuleMedia } from '../TextModule.style'
import { ICMArticle } from '../../../../../../types/cmsPlacement/CMArticle'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import { CmsBroadContentWrapper } from '@components/Cms/CmsComponents-CSS/CmsBroadContentWrapper'
import { CmsTeaserDetailText } from '@components/Cms/CmsComponents-CSS/CmsTeaserDetailText/CmsTeaserDetailText'
import { CmsTeaserTitle } from '@components/Cms/CmsComponents-CSS/CmsTeaserTitle/CmsTeaserTitle'
import styles from '../styles/TextModuleArticle.module.scss'

const TextModuleArticle: React.FC<{ item: ICMArticle }> = ({ item }) => {
  if (!item) return null

  const title = item.title || ''
  const detailText = replaceTextMediaCmsUrl(item.detailText || '')
  const articleTitleAlign = item.articleTitleAlign || 'center'
  const media: TMedia = (item?.media && item?.media[0]) || null

  return (
    <CmsBroadContentWrapper>
      {!!media && (
        <WrapperTextModuleMedia>
          <CMSCommonMedia type={'FULL_WIDTH_BANNER'} media={media} isLazy={true} />
        </WrapperTextModuleMedia>
      )}
      <ContentTextModule>
        {title && (
          <CmsTeaserTitle
            className={clsx(styles.titleArticle, {
              [styles[articleTitleAlign]]: !!articleTitleAlign,
            })}
            dataCmMetadata={`[{"_":"properties.${title}"}]`}
          >
            {title}
          </CmsTeaserTitle>
        )}
        {detailText && (
          <CmsTeaserDetailText dataCmMetadata={`[{"_":"properties.${detailText}"}]`} teaserTextValue={detailText} />
        )}
      </ContentTextModule>
    </CmsBroadContentWrapper>
  )
}

export default TextModuleArticle
