import styled from '@mui/material/styles/styled'
import { StyledTypography } from '../../../../components/UI'

export const StyledSuggestedProductsWrapper = styled('section', {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductsWrapper'
})(({ theme }) => ({
  padding: `${theme.spacing(7)} 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  alignItems: 'center',
  color: theme.palette.text.dark.primary,
  lineHeight: 1.4,

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(10)} 0`,
  },
})
)

export const StyledSuggestedProductsTitle = styled(StyledTypography, {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductTilePopularLabel',
  shouldForwardProp: (prop) => prop !== 'productType'
})<{
  productType: string | null
}>(({ productType, theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: '600',
  lineHeight: '1.875rem',
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('sm')]: {
    fontSize:
      productType === 'contact-lenses' ? '1.25rem' : '1.5rem',
  },
}))

export const StyledSuggestedProductsDescription = styled(StyledTypography, {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductsDescription'
})(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 1.33,
  margin: '0 10vw',
  color: theme.palette.text.dark.primary
}))

export const CarouselContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'CarouselContainer'
})(({ theme }) => ({
  width: '99%',
  [theme.breakpoints.up('sm')]: {
    height: '656px'
  },
  '.swiper-wrapper': {
    marginBottom: '78px !important'
  },
  '.swiper-button-prev, .swiper-button-next': {
    ':after': {
      backgroundColor: 'none'
    }
  }
}))

export const CarouselItem = styled('div', {
  name: 'PdpDetails',
  slot: 'CarouselItem'
})(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}))
