import styled from '@mui/material/styles/styled'
import { teaserBackGroundColorToBg } from '../../../utils/placements'

export const StyledGridOfBoardsContainer = styled('div', {
  name: 'GridOfBoards',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'alignTop',
})<{ alignTop?: boolean }>(({ alignTop = false, theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: alignTop ? 'flex-start' : undefined,
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
}))

export const StyledGridOfBoardsItem = styled('div', {
  name: 'GridOfBoards',
  slot: 'Item',
  shouldForwardProp: prop => prop !== 'columnAmount' && prop !== 'backgroundColor' && prop !== 'marginVertical',
})<{ columnAmount: 2 | 3 | 4; backgroundColor: string; marginVertical?: string }>(
  ({ columnAmount, backgroundColor, marginVertical, theme }) => ({
    display: 'flex',
    alignItems: 'start',
    flex: columnAmount === 2 ? '0 0 50%' : columnAmount === 3 ? '0 0 33%' : '0 0 0 25%',
    padding: theme.spacing(1, 11, 1, 11),
    backgroundColor: teaserBackGroundColorToBg(backgroundColor, theme).backgroundColor,
    bottom: 0,
    [theme.breakpoints.up(769)]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      width: columnAmount == 2 ? '50%' : columnAmount === 3 ? '33.33%' : '25%',
    },
    [theme.breakpoints.between(769, 1023)]: {
      width: columnAmount == 4 ? '33.33%' : undefined,
    },
    [theme.breakpoints.down(769)]: {
      padding: columnAmount < 4 ? theme.spacing(0, 4, 1.5) : undefined,
      width: columnAmount === 4 ? '50%' : undefined,
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      '> .TextModuleWrapper': {
        padding: marginVertical === 'X' ? 0 : undefined,
      },
    },

    '> :first-of-type': {
      paddingBottom: marginVertical === 'X' ? theme.spacing(4) : undefined,
    },
    article: {
      '> :first-of-type': {
        [theme.breakpoints.up('sm')]: {
          marginTop: marginVertical === 'X' ? theme.spacing(0) : theme.spacing(19),
        },
        [theme.breakpoints.up('md')]: {
          marginTop: marginVertical === 'X' ? theme.spacing(0) : theme.spacing(16),
        },
      },
      '> div:first-of-type': {
        marginBottom: marginVertical === 'X' ? theme.spacing(0) : theme.spacing(6),
      },
      '> div:last-of-type': {
        [theme.breakpoints.up('sm')]: {
          marginBottom: marginVertical === 'X' ? theme.spacing(0) : theme.spacing(12),
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: marginVertical === 'X' ? theme.spacing(0) : theme.spacing(5),
        },
      },
    },
  })
)
