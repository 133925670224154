import React, { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
//hooks
import useBreakpoints from '../../../../hooks/useBreakpoints'
// TYPES
import { Swiper as SwiperClass } from 'swiper/types'
//SWIPER
import { SwiperProps } from 'swiper/react'
import {
  StyledSuggestedProductsTitle,
  StyledSuggestedProductsWrapper,
  StyledSuggestedProductsDescription,
  CarouselContainer,
  CarouselItem,
} from './SuggestedProducts.style'
import { SuggestedProductTile } from '../../../../components/SuggestedProductTile'
import { SuggestedProductTileProps } from '../../../../components/SuggestedProductTile/SuggestedProductTile'
import { useTranslation } from 'next-i18next'
import { productTypeSelector } from '../../../../features/product/selector'
import { CarouselGroup } from '../../../../components/UI'
import { PRODUCT_IMAGE_MAX_WIDTH_DESKTOP } from '../../../../constants/common'
import { IProduct } from '@typesApp/product'
export interface SuggestedProductsProps {
  products?: IProduct[]
  sliderProps?: SwiperProps
  mainCarouselWidth?: number
  onPrevClick?: () => void
  onNextClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  currentSlide?: number
  pdpdataloading?: boolean
  showNavigation?: boolean
  centeredSlides?: boolean
  productTileProps?: Partial<SuggestedProductTileProps>
}

const SuggestedProducts: FC<SuggestedProductsProps> = ({
  products,
  currentSlide = 0,
  productTileProps,
}: SuggestedProductsProps) => {
  const { t } = useTranslation()
  const swiperRef = useRef<SwiperClass | null>(null)
  const { isMobile } = useBreakpoints()
  const productType = useSelector(productTypeSelector)
  const deviceBreakpoint = isMobile ? 2 : 3
  useEffect(() => {
    swiperRef.current?.slideToLoop(currentSlide)
  }, [currentSlide])

  const getProductItems = (products?: IProduct[]) => {
    const carouselItems: any[] = []
    products
      ?.filter(product => !!product?.cluster?.[0].x_price.offer || !!product?.cluster?.[0].x_price.list)
      ?.forEach((product, index) => {
        carouselItems.push(
          <CarouselItem>
            <SuggestedProductTile
              isPDP={true}
              isClustered={true}
              product={product}
              tileIndex={index}
              {...productTileProps}
            />
          </CarouselItem>
        )
      })

    return carouselItems
  }

  const carouselTitle = t(`ProductDetails.Suggested.${productType}-title`)
  const carouselDescription = t(`ProductDetails.Suggested.${productType}-description`)

  return (
    <StyledSuggestedProductsWrapper>
      {productType && (
        <>
          <StyledSuggestedProductsTitle productType={productType}>{carouselTitle}</StyledSuggestedProductsTitle>
          {carouselDescription !== '' && (
            <StyledSuggestedProductsDescription>{carouselDescription}</StyledSuggestedProductsDescription>
          )}
        </>
      )}

      <CarouselContainer>
        <CarouselGroup
          items={getProductItems(products)}
          itemsPerView={deviceBreakpoint}
          itemsPerGroup={1}
          colorVariant={'light'}
          paginationVariant={'dark'}
          shapeVariant={'round'}
          darkMode={false}
          maxWidthForImages={`${PRODUCT_IMAGE_MAX_WIDTH_DESKTOP}px`}
        />
      </CarouselContainer>
    </StyledSuggestedProductsWrapper>
  )
}

export default SuggestedProducts
