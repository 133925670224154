import styled from '@mui/material/styles/styled'

export const WrapperQueryList = styled('div', {
  name: 'QueryList',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(16),
    gap: theme.spacing(13),
  },
}))

export const ContentBannerQueryList = styled('div', {
  name: 'QueryList',
  slot: 'Banner',
})(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'grid',
  gap: theme.spacing(9),
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(8),
    rowGap: theme.spacing(11),
  },
}))

export const ContentPaginationQueryList = styled('div', {
  name: 'QueryList',
  slot: 'Pagination',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(8),
}))
