import styled from '@mui/material/styles/styled'
//SWIPER
import { Swiper } from 'swiper/react'


export const PlainSliderContainer = styled('div', {
  name: 'PlainSlider',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'viewtype',
})<{ viewtype: string }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  [theme.breakpoints.up(769)]: {
    marginTop: theme.spacing(16),
    gap: theme.spacing(10),
  },
  [theme.breakpoints.down(769)]: {
    marginTop: theme.spacing(10),
    gap: theme.spacing(8),

  },
}))

export const PlainSliderWrapper = styled('div', {
  name: ' PlainSlider',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'viewtype',
})<{ viewtype: string }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),

  [theme.breakpoints.down(769)]: {
    paddingRight: '0px',
    position: 'relative',
    flexDirection: 'column',
    marginRight: '0',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    flexDirection: 'column',
    marginLeft: '0',
    marginRight: '0',
  },
}))

export const PlainSliderTitleContainer = styled('div', {
  name: ' PlainSlider',
  slot: 'TitleContainer',
  shouldForwardProp: (prop) => prop !== 'bgColorType',
})<{ bgColorType: string }>(({ theme, bgColorType }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  color: bgColorType.includes('dark')
    ? theme.palette.background.light.primary
    : theme.palette.background.dark.primary,
  fontSize: theme.spacing(4),
  gap: theme.spacing(4),
}))

export const PlainSliderTitle = styled('div', {
  name: 'PlainSlider',
  slot: 'Title',
})(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.spacing(6),
  lineHeight: theme.spacing(7.5),
  [theme.breakpoints.down(769)]: {
    fontSize: theme.spacing(5),
  }
}))

export const PlainSliderItem = styled('div', {
  name: 'PlainSlider',
  slot: 'item',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > div': { height: '100%', width: 'auto' },
  '& > div:nth-of-type(2)': {
    h2: {
      marginBottom: theme.spacing(4)
    },
    p: {
      padding: `${'0'} ${theme.spacing(8)}`
    }
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
    flex: '1',
  },
  [theme.breakpoints.up('xs')]: {
    height: '100%',
    flex: '1',
  },
}))

export const PlainSliderImage = styled(Swiper, {
  name: 'Plain',
  slot: 'SliderImage',
  shouldForwardProp: (prop) =>
    prop !== 'paginationwidth' &&
    prop !== 'viewType' &&
    prop !== 'watchFirstItem' &&
    prop !== 'watchLastItem' &&
    prop !== 'bgColorType',
})<{
  paginationwidth?: number
  viewType: any
  watchFirstItem: boolean
  watchLastItem: boolean
  bgColorType: string
}>(({ paginationwidth, theme, watchFirstItem, watchLastItem, bgColorType }) => ({
  maxWidth: '100vw',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '.swiper-wrapper': {
    minWidth: '100%',
    [theme.breakpoints.down(769)]: {
      marginLeft: watchFirstItem ? '-7.48vw' : watchLastItem ? '7.48vw' : 0,
    },
  },
  [theme.breakpoints.down(321)]: {
    marginLeft: theme.spacing(4),
  },

  '.swiper-slide': {
    [theme.breakpoints.up('lg')]: {
      width: '60vw',
      minHeight: theme.spacing(101.5)
    },
    [theme.breakpoints.down(1441)]: {
      width: theme.spacing(216),
    },
    [theme.breakpoints.down('lg')]: {
      width: '60vw'
    },
    [theme.breakpoints.down(1281)]: {
      width: theme.spacing(189.75)
    },
    [theme.breakpoints.down(1280)]: {
      width: '60vw',
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(157.5)
    },
    [theme.breakpoints.down(1024)]: {
      width: '60vw',
    },
    [theme.breakpoints.down(769)]: {
      width: theme.spacing(145.5)
    },
    [theme.breakpoints.down(768)]: {
      width: '76vw',
      height: theme.spacing(77)
    },
    [theme.breakpoints.down(321)]: {
      width: theme.spacing(65.75),
    },

  },
  '.swiper-pagination': {
    position: 'absolute',
    bottom: theme.spacing(9),
    width: '100%',
    [theme.breakpoints.down(321)]: {
      right: theme.spacing(4),
    },
  },
  '.swiper-button-prev': {
    color: bgColorType.includes('dark')
        ? theme.palette.background.light.primary
        : theme.palette.background.dark.primary,
    position: 'absolute',
    top: theme.spacing(5.5),
    height: '100%',
    [theme.breakpoints.up(1680)]: {
      left: '17.85%'
    },
    [theme.breakpoints.between(1440, 1680)]: {
      left: '17.35%'
    },
    [theme.breakpoints.down(1441)]: {
      left: '16.85%'
    },
    [theme.breakpoints.down(1281)]: {
      left: '16.7%'
    },
    [theme.breakpoints.down(1025)]: {
      left: '15%'
    },
    '&::after': {
      fontSize: theme.spacing(7),
      fontWeight: 'bold'
    }
  },
  '.swiper-button-next': {
    color: bgColorType.includes('dark')
        ? theme.palette.background.light.primary
        : theme.palette.background.dark.primary,
    position: 'absolute',
    top: theme.spacing(5.5),
    height: '100%',
    [theme.breakpoints.up(1680)]: {
      right: '17.85%'
    },
    [theme.breakpoints.between(1440, 1680)]: {
      right: '17.35%'
    },
    [theme.breakpoints.down(1441)]: {
      right: '16.85%'
    },
    [theme.breakpoints.down(1281)]: {
      right: '16.7%'
    },
    [theme.breakpoints.down(1025)]: {
      right: '15%'
    },
    '&::after': {
      fontSize: theme.spacing(7),
      fontWeight: 'bold'
    }
  },
  '.swiper-pagination-bullet': {
    borderRadius: 0,
    width: `${paginationwidth ? paginationwidth : 60}px`,
    height: 2,
    opacity: 1,
    marginLeft: '6px',
    background: theme.palette.background.light.tertiary,
    marginBottom: '-40px !important',
    '&.swiper-pagination-bullet-active': {
      background: theme.palette.background.dark.primary
    },
    [theme.breakpoints.down('md')]: {
      width: `${paginationwidth ? paginationwidth : 50}px`,
    }
  }
}))

export const PlainSliderText = styled(Swiper, {
  name: 'PlainSlider',
  slot: 'Text',
  shouldForwardProp: (prop) =>
    prop !== 'paginationwidth' && prop !== 'viewType' &&
    prop !== 'bgColorType',
})<{
  paginationwidth?: number;
  viewType: any;
  bgColorType: string;
}>(({ paginationwidth, bgColorType, theme }) => ({
  maxWidth: '100vw',
  paddingBottom: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '.swiper-wrapper': {
    minWidth: '100%',
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(10),
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(8),
  },
  '.swiper-slide': {
    [theme.breakpoints.down(1441)]: {
      width: theme.spacing(216),
    },
  },
  '.swiper-pagination': {
    margin: `${theme.spacing(9.5)} auto`,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  '.swiper-button-prev': {
    color: bgColorType.includes('dark')
      ? theme.palette.background.light.primary
      : theme.palette.background.dark.primary,
    position: 'absolute',
    top: 0,
    height: '100%',
    '&::after': {
      fontSize: theme.spacing(7),
      fontWeight: 'bold',
    },
  },
  '.swiper-button-next': {
    color: bgColorType.includes('dark')
      ? theme.palette.background.light.primary
      : theme.palette.background.dark.primary,
    position: 'absolute',
    top: 0,
    height: '100%',
    '&::after': {
      fontSize: theme.spacing(7),
      fontWeight: 'bold',
    },
  },
  '.swiper-pagination-bullet': {
    borderRadius: 0,
    width: `${paginationwidth ? paginationwidth : 60}px`,
    height: 2,
    opacity: 1,
    marginLeft: '6px',
    background: theme.palette.background.light.tertiary,
    marginBottom: '-40px !important',
    '&.swiper-pagination-bullet-active': {
      background: theme.palette.background.dark.primary,
    },
    [theme.breakpoints.down('md')]: {
      width: `${paginationwidth ? paginationwidth : 50}px`,
    },
  },
  '#inActiveSlide': {
    a: {
      transition: 'all .3s ease-in-out',
      opacity: '0',
    },
  },
}))

export const PlainSlider = styled(Swiper, {
  name: 'PlainSlider',
  slot: 'Slider',
})<{}>(() => ({
  width: '100%',
}))

export const PlainSliderTextContainer = styled('div', {
  name: 'PlainSliderText',
  slot: 'Container',
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' && prop !== 'bgColorType',
})<{ backgroundColor: string; bgColorType: string }>(
  ({ theme, backgroundColor, bgColorType }) => ({
    display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: bgColorType.includes('dark')
      ? theme.palette.background.light.primary
      : theme.palette.background.dark.primary,
  backgroundColor:
    backgroundColor === 'transparent'
      ? theme.palette.background.light.primary
      : backgroundColor,
  width: '100%',
  'div:first-of-type': {
    [theme.breakpoints.up(768)]: {
      width: theme.spacing(188),
    },
    [theme.breakpoints.down(769)]: {
      width: theme.spacing(72),
    },
  }
}))

export const TextContainer = styled('div', {
  name: 'TextModule',
  slot: 'TextContainer',
  shouldForwardProp: (prop) => prop !== 'textAlign',
})<{
  textAlign: React.CSSProperties['textAlign'];
}>(({ textAlign, theme }) => ({
  p: {
    margin: ` ${'0'} ${theme.spacing(8)}`,
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px'
    },
  },
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
  h1: {
    fontSize: 60
  },
  h2: {
    fontSize: 40
  },
  h3: {
    fontSize: 32
  },
  '.text-small': {
    fontSize: 12
  },
  '.text-large': {
    fontSize: 40
  }
}))
