import React, { useContext } from 'react'
import GenericBanner from '../GenericBanner'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import styles from './styles/SquatBanner.module.scss'

const SquatBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null

  return (
    <GenericBanner
      crop="SQUAT_BANNER_XS"
      className={styles.squatBanner}
      lazy
      termsAndConditionsClassName={styles.squatBannerTermsConditions}
      videoWrapperClassName={styles.squatBannerVideoWrapper}
    />
  )
}

export default SquatBanner
