import React, { useMemo, useState } from 'react'
import { isCMChannel } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMQueryList } from '@typesApp/cmsPlacement/CMQueryList'
import {
  ContentPaginationQueryList,
  ContentBannerQueryList,
  WrapperQueryList,
} from './QueryList.style'
import ElementQueryList from './components/ElementQueryList'
import PaginationQueryList from './components/PaginationQueryList'

const QueryList: React.FC<{ placement: ICMQueryList | ICMCollection }> = ({
  placement,
}) => {
  const [page, setPage] = useState<number>(1)
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const teasableItems = placement?.teasableItems?.filter(isCMChannel) || []
  const pageSize = 9

  const paginate = teasableItems.slice((page - 1) * pageSize, page * pageSize)
  const count = useMemo<number>(
    () => Math.ceil(teasableItems.length / pageSize),
    [teasableItems]
  )
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) =>
    setPage(value)

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <WrapperQueryList {...metaData}>
      <ContentBannerQueryList>
        {paginate.map((item) => (
          <React.Fragment key={item.id}>
            <ElementQueryList item={item} />
          </React.Fragment>
        ))}
      </ContentBannerQueryList>
      <ContentPaginationQueryList>
        <PaginationQueryList
          page={page}
          count={count}
          onChange={handleChange}
        />
      </ContentPaginationQueryList>
    </WrapperQueryList>
  )
}

export default QueryList
