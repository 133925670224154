import React from 'react'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import MediaOverlap from '../../CmsModules/MediaOverlapV2'
import TextModule from '../../CmsModules/TextModule'
import {
  SquareBoardsContainer,
  SquareBoardsItem,
  SquareBoardsMediaWrapper,
  SquareBoardsTextWrapper,
} from './SquareBoards.style'

export interface IProps {
  placement: IPlacement | ICMCollection
  split?: boolean
  order?: string
}

const SquareBoards: React.FC<IProps> = ({ placement, split, order }) => {
  const teasers =
    'items' in placement
      ? placement.items.filter(isLXTeaser)
      : placement.teasableItems.filter(isLXTeaser)

  return (
    <SquareBoardsContainer order={order} split={split}>
      {teasers.map((teaser, index) => (
        <SquareBoardsItem key={teaser.id}>
          <SquareBoardsMediaWrapper>
            <MediaOverlap crop={'SQUARE_BOARDS'} teaser={teaser} />
            <MediaOverlap />
          </SquareBoardsMediaWrapper>
          <SquareBoardsTextWrapper>
            <TextModule
              teaserIndex={index}
              teaser={teaser}
              viewType={placement.viewtype}
            />
          </SquareBoardsTextWrapper>
        </SquareBoardsItem>
      ))}
    </SquareBoardsContainer>
  )
}

export default SquareBoards
