import React from 'react'
import {
  SquareBoardWithSplitItem,
  SquareBoardWithSplitWrapper,
} from './SquareBoardWithSplit.style'
import SquareBoardsWithSplitMediaOverlap from './components/SquareBoardsWithSplitMediaOverlap'
import { SquareBoardWithSplitText } from './components/SquareBoardsWithSplitText'
import {
  IPlacement,
  isLXTeaser,
} from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
export interface ISquareBoardWithSplitProps {
  placement: IPlacement | ICMCollection
  teaserIndex?: number
  reflect?: boolean
}

const SquareBoardWithSplit: React.FC<ISquareBoardWithSplitProps> = ({
  placement,
  reflect,
  teaserIndex,
}) => {
  const teasers =
    'items' in placement
      ? placement?.items.filter(isLXTeaser)
      : placement?.teasableItems.filter(isLXTeaser)
  const order: string = reflect ? '1' : '0'
  const isComboMiniSlider =
    placement?.viewtype === 'combo-mini-slider-plus-box-all-fields'

  return (
    <SquareBoardWithSplitWrapper
      order={order}
      isComboMiniSlider={isComboMiniSlider}
    >
      {teasers?.map((teaser, i) => (
        <SquareBoardWithSplitItem key={teaser.id}>
          <SquareBoardsWithSplitMediaOverlap
            crop={
              isComboMiniSlider
                ? 'COMBO_MINI_SLIDER_BIG'
                : 'SQUARE_BOARDS_WITH_SPLIT'
            }
            teaser={teaser}
            teaserIndex={teaserIndex}
            bannerIndex={i + 1}
            viewType={placement?.viewtype || 'default'}
          />
          <SquareBoardWithSplitText
            bannerIndex={i + 1}
            teaser={teaser}
            viewType={placement?.viewtype}
          />
        </SquareBoardWithSplitItem>
      ))}
    </SquareBoardWithSplitWrapper>
  )
}

export default SquareBoardWithSplit
