import styled from '@mui/material/styles/styled'
import { StyledTypography } from '../../../UI'
import { Link } from '@components/common/Link/Link'

export const WrapperElementQueryList = styled('div', {
  name: 'ElementQueryList',
  slot: 'Wrapper',
})(() => ({}))

export const Title = styled(StyledTypography, {
  name: 'ElementQueryList',
  slot: 'Title',
})(() => ({}))

export const Text = styled(StyledTypography, {
  name: 'ElementQueryList',
  slot: 'Text',
})(() => ({
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}))

export const LinkElementQueryList = styled(Link, {
  name: 'ElementQueryList',
  slot: 'Link',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  color: theme.palette.text.dark.primary,
}))

export const ContentElementQueryList = styled('div', {
  name: 'ElementQueryList',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: `0 ${theme.spacing(2)}`,
}))
