import React from 'react'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { ValuePropBannerItem, ValuePropBannerItemIcon, ValuePropBannerWrapper } from './ValuePropBanner.style'
import theme from '../../../../themes/Clearly/light-theme'

export interface ValuePropBannerProps {
  data?: IPlacement
}

export const ValuePropBanner = (props: ValuePropBannerProps) => {
  const { data } = props

  const valuePropositions = data?.items.map(item => {
    const betterTypeItem = { ...item } as ILXTeaser
    return (
      <ValuePropBannerItem key={betterTypeItem.id || betterTypeItem.title}>
        <ValuePropBannerItemIcon>
          <CmsIcon teaserIcon={betterTypeItem?.teaserIcon} size={16} color={theme.palette.text.dark.secondary} />
        </ValuePropBannerItemIcon>
        {betterTypeItem.teaserTitle1}
      </ValuePropBannerItem>
    )
  })

  return <ValuePropBannerWrapper tabIndex={0}>{valuePropositions}</ValuePropBannerWrapper>
}
