import styled from '@mui/material/styles/styled'

export const StyledGridOfProductsContainer = styled('div', {
  name: 'DcwProducts',
  slot: 'StyledGridOfProductsContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  a: {
    flexGrow: 0,
    whiteSpace: 'nowrap',
  },
}))

export const StyledGridOfProductsItem = styled('div', {
  name: 'DcwProducts',
  slot: 'StyledGridOfProductsContainer',
  shouldForwardProp: (prop) => prop !== 'columnAmount',
})(({ theme }) => ({
  display: 'flex',
  flex: '0 0 50%',
  [theme.breakpoints.up('sm')]: {
    flex: '0 0 25%',
  },
}))

export const StyledSuggestionContainer = styled('div', {
  name: 'DcwProducts',
  slot: 'StyledSuggestionContainer',
})(({ theme }) => ({
  gridColumn: '1 / -1',
  backgroundColor: theme.palette.background.light.secondary,
}))
