import React from 'react'
import { TabWithStyle } from './StyledTab.style'
import { StyledTabProps } from '../../../types/tab'
import { uniqueId } from '../../../utils/common'

export const StyledTab = ({ parentbg, ...props }: StyledTabProps) => {
  function a11yProps(index, name) {
    return {
      id: `${name}-tab-${uniqueId(`${index}-`)}`,
      'aria-controls': `${name}-tabpanel-${uniqueId(`${index}-`)}`,
    }
  }

  return (
    <TabWithStyle
      parentbg={parentbg}
      {...a11yProps(props.key, props.name)}
      {...props}
    />
  )
}
