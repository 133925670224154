import React, { FC, useState } from 'react'

import { SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'

import { IPlacement, isCMCollection, isLXTeaser, isVideoMedia } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { getMediaByDeviceType } from '../../../hooks/useMediaByDeviceType'

import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'

import * as S from './ComboMiniSlider.style'

import useBreakpoints from '../../../hooks/useBreakpoints'
import CallToAction from '../call-to-action'
import usePlayerBanner from '../../../hooks/useBannerPlayer'
import { StyledButtonAsLink } from '../../UI'
import SquareBoards from '../SquareBoards'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
export interface ComboMiniSliderProps {
  teaserIndex?: number
  placement: IPlacement | ICMCollection
  reflect?: boolean
}

const ComboMiniSlider: FC<ComboMiniSliderProps> = props => {
  const { teaserIndex, placement, reflect } = props

  const { isMobile, isTablet } = useBreakpoints()
  const [swiper, setSwiper] = useState<SwiperCore>()
  const showArrows = true
  const handleNextSlide = () => swiper?.slideNext()
  const handlePrevSlide = () => swiper?.slidePrev()

  const ARE_ITEMS_IN_PLACEMENT = 'items' in placement

  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''

  const banner = ARE_ITEMS_IN_PLACEMENT
    ? placement.items.filter(isLXTeaser)[0]
    : placement.teasableItems.filter(isLXTeaser)[0]

  const isLooped = placement.viewtype === 'combo-mini-slider-plus-box-all-fields'
  const isLazy = placement.viewtype === 'combo-mini-slider-plus-box-all-fields' ? 0 : 1

  const comboMiniItems = ARE_ITEMS_IN_PLACEMENT
    ? placement.items.filter(isCMCollection)[0]
    : placement.teasableItems.filter(isCMCollection)[0]

  const media = banner.media?.[0] ?? undefined
  const video = isVideoMedia(media) ? media : undefined
  const playerBanner = usePlayerBanner(video)

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <S.StyledContainer {...metaData} invertFlexDirection={reflect} isMobile={isMobile || isTablet}>
      <S.StyledLeft>
        <S.StyledContent className="content">
          {comboMiniItems.collectionTitle && (
            <S.StyledTitle variant="h3" data-cm-metadata={'[{"_":"properties.teaserTitle3"}]'}>
              {comboMiniItems.collectionTitle}
            </S.StyledTitle>
          )}

          <S.StyledCollectionInfoSection>
            <S.StyledCarouselContentContainer>
              <h3>{comboMiniItems.collectionSubTitle}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: comboMiniItems?.collectionText,
                }}
              />
            </S.StyledCarouselContentContainer>
            <S.StyledCTAContainer className="cta-container">
              {comboMiniItems?.teaserLXCallToActionSettings?.map((item, index) => (
                <CallToAction
                  dataElementId={`X_X_Combo_Mini_Slider_${teaserIndex}Placement_Banner_${item.callToActionText?.replaceAll(
                    ' ',
                    '_'
                  )}_CTA${index}`}
                  key={`dc-cms-content__cta-${index}`}
                  {...item}
                />
              ))}
            </S.StyledCTAContainer>
          </S.StyledCollectionInfoSection>

          <S.StyledSlider>
            {showArrows && (
              <S.StyledArrowDiv>
                <StyledButtonAsLink onClick={handlePrevSlide}>
                  <SVGIcon library="arrow" name="arrow-left" />
                </StyledButtonAsLink>
              </S.StyledArrowDiv>
            )}
            <S.ComboMiniSliderSwiper
              slidesPerView={showArrows ? 1 : 1.2}
              centeredSlides={true}
              modules={[Navigation, Pagination]}
              onSwiper={setSwiper}
              pagination={{
                modifierClass: 'custom-',
                progressbarFillClass: 'custom-progressbar-fill',
                type: 'progressbar',
              }}
              loop={isLooped}
            >
              {comboMiniItems.teasableItems.map((teaser, index) => (
                <SwiperSlide key={`cle-slide__${index}`}>
                  <S.CMSMediaContainer>
                    <CMSCommonMedia
                      type="BOX_WITH_MARGIN_BANNER"
                      media={getMediaByDeviceType(isMobile, teaser?.media ?? [])}
                      isLazy={true}
                    />
                  </S.CMSMediaContainer>
                  {(teaser as ILXTeaser).teaserTitle1 && (
                    <S.StyledCarouselContentContainer>
                      <h3>{(teaser as ILXTeaser).teaserTitle1}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: (teaser as ILXTeaser).teaserText1,
                        }}
                      />
                      {(teaser as ILXTeaser).teaserLabelText && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: (teaser as ILXTeaser).teaserLabelText,
                          }}
                        />
                      )}
                    </S.StyledCarouselContentContainer>
                  )}
                  {(teaser as ILXTeaser).teaserLXCallToActionSettings.length > 0 && (
                    <S.StyledCTAContainer className="cta-container">
                      {(teaser as ILXTeaser).teaserLXCallToActionSettings.map((item, index) => (
                        <CallToAction
                          dataElementId={`X_X_Combo_Mini_Slider_${teaserIndex}Placement_Banner1_${item.callToActionText?.replaceAll(
                            ' ',
                            '_'
                          )}CTA0`}
                          key={`dc-cms-content__cta-${index}`}
                          {...item}
                        />
                      ))}
                    </S.StyledCTAContainer>
                  )}
                </SwiperSlide>
              ))}
            </S.ComboMiniSliderSwiper>
            {showArrows && (
              <S.StyledRightArrowDiv>
                <StyledButtonAsLink onClick={handleNextSlide}>
                  <SVGIcon library="arrow" name="arrow-right" />
                </StyledButtonAsLink>
              </S.StyledRightArrowDiv>
            )}
          </S.StyledSlider>
        </S.StyledContent>
      </S.StyledLeft>
      <S.StyledRight className="dc-cms__second-item">
        <S.StyledRightContainer {...playerBanner.events}>
          <SquareBoards placement={placement} />
        </S.StyledRightContainer>
      </S.StyledRight>
    </S.StyledContainer>
  )
}

export default ComboMiniSlider
