export interface IBrands {
  name: string
  id: string
  logo: string
  background?: string
}

const brandListClearly: IBrands[] = [
  {
    'name': '7 for All Mankind',
    'id': '7a',
    'logo': '/images/brands/7-for-all-mankind.svg'
  },
  {
    'name': 'Alain Mikli',
    'id': 'a0',
    'logo': '/images/brands/alain-mikli.svg'
  },
  {
    'name': 'Armani Exchange',
    'id': 'ax',
    'logo': '/images/brands/armani-exchange.svg'
  },
  {
    'name': 'Arnette',
    'id': 'ar',
    'logo': '/images/brands/arnette.svg'
  },
  {
    'name': 'Bvlgari',
    'id': 'bv',
    'logo': '/images/brands/bvlgari.svg'
  },
  {
    'name': 'Brooks Brothers',
    'id': 'bb',
    'logo': '/images/brands/brooks-brothers.svg'
  },
  {
    'name': 'Bolon',
    'id': 'bo',
    'logo': '/images/brands/bolon.svg'
  },
  {
    'name': 'Bottega Veneta',
    'id': 'bv',
    'logo': '/images/brands/bottega-veneta.svg'
  },
  {
    'name': 'Cartier',
    'id': '6l',
    'logo': '/images/brands/cartier.svg'
  },
  {
    'name': 'CHANEL',
    'id': 'ch',
    'logo': '/images/brands/chanel.svg'
  },
  {
    'name': 'Chaps',
    'id': 'cp',
    'logo': '/images/brands/chaps.svg'
  },
  {
    'name': 'Clearly',
    'id': 'cl',
    'logo': '/images/brands/clearly.svg'
  },
  {
    'name': 'Clearly Basics',
    'id': 'cb',
    'logo': '/images/brands/clearly-basics.svg'
  },
  {
    'name': 'pl', /* Private Labels */
    'id': 'pl',
    'logo': '/images/brands/clearly.svg'
  },
  {
    'name': 'Clearly Gamer',
    'id': 'cg',
    'logo': '/images/brands/clearly-gamer.svg'
  },
  {
    'name': 'Coach',
    'id': 'hc',
    'logo': '/images/brands/coach.svg',
  },
  {
    'name': 'Coach New York',
    'id': 'cn',
    'logo': '/images/brands/coach-newyork.svg'
  },
  {
    'name': 'Coach',
    'id': 'cn',
    'logo': '/images/brands/coach-newyork.svg'
  },
  {
    'name': 'Crocs',
    'id': 'cr',
    'logo': '/images/brands/crocs.svg'
  },
  {
    'name': 'Cruz',
    'id': 'cz',
    'logo': '/images/brands/cruz.svg'
  },
  {
    'name': 'Derek Cardigan',
    'id': 'dc',
    'logo': '/images/brands/derek-cardigan.svg'
  },
  {
    'name': 'Ellen Degeneres',
    'id': 'ed',
    'logo': '/images/brands/ellen-degeneres.svg'
  },
  {
    'name': 'Polo Prep',
    'id': 'pp',
    'logo': '/images/brands/polo-prep.svg'
  },
  {
    'name': 'Polo Ralph Lauren',
    'id': 'ph',
    'logo': '/images/brands/polo-ralph-lauren.svg'
  },
  {
    'name': 'Ralph Lauren Eyewear',
    'id': 'rl',
    'logo': '/images/brands/ralph-lauren-eyewear.svg'
  },
  {
    'name': 'Ralph by Ralph Lauren',
    'id': 'rh',
    'logo': '/images/brands/ralph-by-ralph-lauren.svg'
  },
  {
    'name': 'Reincarnate',
    'id': 're',
    'logo': '/images/brands/reincarnate.svg'
  },
  {
    'name': 'Michael Kors',
    'id': 'mk',
    'logo': '/images/brands/michael-kors.svg'
  },
  {
    'name': 'Dior',
    'id': 'dr',
    'logo': '/images/brands/dior.svg'
  },
  {
    'name': 'Dolce & Gabbana',
    'id': 'dg',
    'logo': '/images/brands/dolce-gabbana.svg'
  },
  {
    'name': 'Emporio Armani',
    'id': 'ea',
    'logo': '/images/brands/emporio-armani.svg'
  },
  {
    'name': 'Giorgio Armani',
    'id': 'ar',
    'logo': '/images/brands/giorgio-armani.svg'
  },
  {
    'name': 'Gucci',
    'id': 'gc',
    'logo': '/images/brands/gucci.svg'
  },
  {
    'name': 'Jhane Barnes',
    'id': 'jb',
    'logo': '/images/brands/jhane-barnes.svg'
  },
  {
    'name': 'Joseph Marc',
    'id': 'jm',
    'logo': '/images/brands/joseph-marc.svg'
  },
  {
    'name': 'Kam Dhillon',
    'id': 'kd',
    'logo': '/images/brands/kam-dhillon.svg'
  },
  {
    'name': 'Konishi',
    'id': 'ko',
    'logo': '/images/brands/konishi.svg'
  },
  {
    'name': 'Marc Jacobs',
    'id': 'mj',
    'logo': '/images/brands/marc-jacobs.svg'
  },
  {
    'name': 'Tiffany',
    'id': 'tf',
    'logo': '/images/brands/tiffany-co.svg'
  },
  {
    'name': 'Miu Miu',
    'id': 'mu',
    'logo': '/images/brands/miu-miu.svg'
  },
  {
    'name': 'Modo',
    'id': 'mo',
    'logo': '/images/brands/modo.svg'
  },
  {
    'name': 'Nike',
    'id': 'ni',
    'logo': '/images/brands/nike.svg'
  },
  {
    'name': 'Oakley',
    'id': 'oo',
    'logo': '/images/brands/oakley.svg',
    'background': 'banner-oakley.png'
  },
  {
    'name': 'Persol',
    'id': 'po',
    'logo': '/images/brands/persol.svg'
  },
  {
    'name': 'Perspective',
    'id': 'ps',
    'logo': '/images/brands/perspective.svg'
  },
  {
    'name': 'Prada',
    'id': 'pr',
    'logo': '/images/brands/prada.svg'
  },
  {
    'name': 'Prada Eyewear',
    'id': 'pre',
    'logo': '/images/brands/prada-eyewear.svg'
  },
  {
    'name': 'Prada Linea Rossa',
    'id': 'ps',
    'logo': '/images/brands/prada-linea-rossa.svg'
  },
  {
    'name': 'Ray-Ban',
    'id': 'rb',
    'logo': '/images/brands/ray-ban.svg'
  },
  {
    'name': 'Ray-Ban 1937',
    'id': 'rbl',
    'logo': '/images/brands/ray-ban-1937.svg'
  },
  {
    'name': 'Seventyone',
    'id': '71',
    'logo': '/images/brands/seventyone.svg'
  },
  {
    'name': 'Sferoflex',
    'id': 'sf',
    'logo': '/images/brands/sferoflex.svg'
  },
  {
    'name': 'Tom Ford',
    'id': 'tr',
    'logo': '/images/brands/tom-ford.svg'
  },
  {
    'name': 'Tory Burch',
    'id': 'tb',
    'logo': '/images/brands/tory-burch.svg'
  },
  {
    'name': 'Valentino',
    'id': 'va',
    'logo': '/images/brands/valentino.svg'
  },
  {
    'name': 'Versace',
    'id': 've',
    'logo': '/images/brands/versace.svg'
  },
  {
    'name': 'Vogue',
    'id': 'vo',
    'logo': '/images/brands/vogue.svg'
  },
  {
    'name': 'XXL',
    'id': 'vo',
    'logo': '/images/brands/xxl-eyewear.svg'
  },
  {
    'name': 'Lindberg',
    'id': 'lb',
    'logo': '/images/brands/lindberg.svg'
  },
  {
    'name': 'Main Central',
    'id': 'mc',
    'logo': '/images/brands/main-central.svg'
  },
  {
    'name': 'Main and Central',
    'id': 'mc',
    'logo': '/images/brands/main-central.svg'
  },
  {
    'name': 'Alexander',
    'id': 'al',
    'logo': '/images/brands/alexander-collection.svg'
  },
  {
    'name': 'Paul Smith',
    'id': 'pm',
    'logo': '/images/brands/paul-smith-spectacles.svg'
  },
  {
    'name': 'Starck Eyes',
    'id': 'st',
    'logo': '/images/brands/starck.svg'
  },
  {
    'name': 'Superdry',
    'id': 'sd',
    'logo': '/images/brands/superdry.svg'
  },
  {
    'name': 'Acuvue',
    'id': 'ac',
    'logo': '/images/brands/acuvue.svg'
  },
  {
    'name': 'Air Optix',
    'id': 'ao',
    'logo': '/images/brands/air-optix.svg'
  },
  {
    'name': 'Bausch & Lomb',
    'id': 'bl',
    'logo': '/images/brands/bausch-lomb.svg'
  },
  {
    'name': 'Biofinity',
    'id': 'bi',
    'logo': '/images/brands/biofinity.svg'
  },
  {
    'name': 'Biomedics',
    'id': 'bm',
    'logo': '/images/brands/biomedics.svg'
  },
  {
    'name': 'Biotrue',
    'id': 'bt',
    'logo': '/images/brands/biotrue.svg'
  },
  {
    'name': 'Clariti',
    'id': 'ct',
    'logo': '/images/brands/clariti.svg'
  },
  {
    'name': 'Dailies',
    'id': 'dl',
    'logo': '/images/brands/dailies.svg'
  },
  {
    'name': 'Everclear',
    'id': 'ec',
    'logo': '/images/brands/everclear.svg'
  },
  {
    'name': 'Freshlook',
    'id': 'fl',
    'logo': '/images/brands/freshlook.svg'
  },
  {
    'name': 'MyDay',
    'id': 'md',
    'logo': '/images/brands/myday.svg'
  },
  {
    'name': 'Precision 1',
    'id': 'pc',
    'logo': '/images/brands/precision1.svg'
  },
  {
    'name': 'Proclear',
    'id': 'pcr',
    'logo': '/images/brands/proclear.svg'
  },
  {
    'name': 'Pure Vision',
    'id': 'pv',
    'logo': '/images/brands/pure-vision.svg'
  },
  {
    'name': 'SofLens',
    'id': 'sl',
    'logo': '/images/brands/sofLens.svg'
  },
  {
    'name': 'Splash',
    'id': 'sp',
    'logo': '/images/brands/splash.svg'
  },
  {
    'name': 'Total',
    'id': 'to',
    'logo': '/images/brands/total.svg'
  },
  {
    'name': 'Ultra',
    'id': 'ul',
    'logo': '/images/brands/ultra.svg'
  }
]

export default brandListClearly
