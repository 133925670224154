import React from 'react'

import { ModulesProps } from '../../../types/cms'
import { ITablePlacement } from '@typesApp/cmsPlacement/Placement'
import TableModuleArticle from './components/TableModuleArticle'

export interface ITableModuleProps extends ModulesProps {
  placement?: ITablePlacement
  placementCenter?: boolean
}

const TableModule: React.FC<ITableModuleProps> = ({
  placement
}) => {
  const areItemsInPlacement = placement && 'items' in placement
  if (areItemsInPlacement) {
    return <>
      {placement.items.map((item, index) => <TableModuleArticle item={item} key={index} />)}
    </>
  }
  return null
}

export default TableModule
