import React from 'react'
import { StyledTypography } from '../../UI'
import styled from '@mui/material/styles/styled'
import {
  StyledTextModuleContainer,
  StyledTextModuleFullWidthWrapper,
  StyledTextModuleWrapper,
} from '../TextModuleV2/TextModule.style'
import { StyledTextModuleFullWidthBannerWrapper } from '../TextModule/TextModule.style'

export const StyledTableModuleWrapper = styled(StyledTextModuleWrapper, {
  name: 'TableModule',
  slot: 'Wrapper',
})(() => ({}))

export const StyledTableModuleFullWidthWrapper = styled(StyledTextModuleFullWidthWrapper, {
  name: 'TableModule',
  slot: 'FullWidthWrapper',
})(() => ({}))

export const StyledTableModuleFullWidthBannerWrapper = styled(StyledTextModuleFullWidthBannerWrapper, {
  name: 'TableModule',
  slot: 'FullWidthBannerWrapper',
})(() => ({}))

export const StyledTableModuleContainer = styled(StyledTextModuleContainer, {
  name: 'TableModule',
  slot: 'Container',
})(() => ({}))

export const StyledTitle = styled(StyledTypography, {
  name: 'TableModule',
  slot: 'Title',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'external',
})<{
  textAlign: React.CSSProperties['textAlign']
  external?: boolean
}>(({ theme, textAlign, external }) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: '700',
  lineHeight: 1.5,
  textAlign,
  [theme.breakpoints.up('md')]: {
    textAlign,
  },
  [theme.breakpoints.up(769)]: {
    fontSize: external ? theme.typography.h2.fontSize : theme.typography.h4.fontSize,
    marginBottom: theme.spacing(10),
    marginTop: external ? theme.spacing(16) : 0,
  },
  [theme.breakpoints.down(769)]: {
    fontSize: external ? theme.typography.h4.fontSize : theme.typography.body1.fontSize,
    marginBottom: theme.spacing(8),
    marginTop: external ? theme.spacing(10) : 0,
  },
}))

export const TableContainer = styled('div', {
  name: 'TableModule',
  slot: 'TableContainer',
  shouldForwardProp: prop => prop !== 'textAlign' && prop !== 'external' && prop !== 'smallViewWidth',
})<{
  textAlign?: React.CSSProperties['textAlign']
  smallViewWidth?: React.CSSProperties['width']
}>(({ textAlign, smallViewWidth: smallViewWidth, theme }) => ({
  display: 'flex',
  justifyContent: textAlign ?? 'center',
  marginTop: theme.spacing(4),
  h2: {
    position: 'sticky',
    left: '0',
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.9rem',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  },

  p: {
    '&.center': {
      textAlign: 'center',
    },
  },
  'div > p': {
    position: 'sticky',
    left: '0',
    padding: '1rem',
  },
  div: {
    overflowX: 'scroll',
    '::-webkit-scrollbar': {
      height: 0,
      [theme.breakpoints.down('sm')]: {
        height: '0.5rem',
      },
    },
    '::-webkit-scrollbar-track': {
      'background-color': theme.palette.custom.light.grey,
    },
    '::-webkit-scrollbar-thumb': {
      'background-color': theme.palette.custom.black,
    },

    table: {
      borderCollapse: 'collapse',
      margin: `0 0 ${theme.spacing(5)} 0`,
      [theme.breakpoints.down('sm')]: {
        width: smallViewWidth || '200%',
        tableLayout: 'fixed',
      },

      tr: {
        position: 'relative',
        padding: '1rem',
        ':first-of-type': {
          td: {
            fontWeight: 700,
            textAlign: 'center',
          },
        },
        td: {
          borderBottom: `1px solid ${theme.palette.custom.light2.grey}`,
          padding: '1rem',
          verticalAlign: 'middle',
          width: '20%',
          [theme.breakpoints.down('sm')]: {
            width: '10%',
          },
          wordBreak: 'break-word',
          ':nth-child(even)': {
            backgroundColor: theme.palette.custom.light.grey,
          },
          ':first-of-type': {
            [theme.breakpoints.down(768)]: {
              position: 'sticky',
              left: '0',
              backgroundColor: theme.palette.background.light.primary,
            },
          },
          p: {
            margin: 0,
          },
          'p + p:has(br)': {
            display: 'none',
          },
          img: {
            height: 'auto',
            objectFit: 'cover',
            width: '100%',
          },
        },
        ':last-child td': {
          borderBottom: 0,
        },
      },
    },
  },
}))
