import React, { useState } from 'react'
import { Controller, EffectFade, Navigation } from 'swiper'
import { SwiperSlide, SwiperProps } from 'swiper/react'
import { ModulesProps } from '../../../types/cms'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import {
  TextContainer,
  PlainSliderContainer,
  PlainSliderTitleContainer,
  PlainSliderTitle,
  PlainSliderWrapper,
  PlainSliderImage,
  PlainSliderItem,
  PlainSliderText,
  PlainSliderTextContainer,
} from './PlainSlider.style'
import MediaOverlap from '../../CmsModules/MediaOverlapV2'
import HTMLReactParser from 'html-react-parser'
import { useMediaQuery } from '@mui/material'
import { useTheme } from 'styled-components'
import TextModulev2 from '../../CmsModules/TextModuleV2'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { teaserBackGroundColorToBg } from '@utils/placements'

export interface PlainSliderModuleProps extends ModulesProps {
  placement: IPlacement | ICMCollection,
  firstItem: IPlacement | ICMCollection
}

const PlainSlider: React.FC<PlainSliderModuleProps> = ({
  placement,
  firstItem,
}) => {
  const theme = useTheme()
  const isTablet769below = useMediaQuery(theme.breakpoints.down(769))
  const isMobile = useMediaQuery(theme.breakpoints.down(321))
  let teasers =
    'items' in placement
    ? placement.items.filter(isLXTeaser)
    : placement.teasableItems.filter(isLXTeaser)
  const crop = 'PLAIN_SLIDER'
  const [controlledSwiper, setControlledSwiper] = useState<any>(true)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const initialSlide = isTablet769below ? 0 : Math.floor(teasers.length / 2)
  const isFirstItem = activeIndex === 0
  const isLastItem = activeIndex === teasers.length - 1

  const sliderPropsImage: SwiperProps = {
    spaceBetween: isTablet769below ? 16 : 56,
    slidesPerView: 'auto',
    centeredSlides: !isMobile,
    modules: [Controller, Navigation],
    loop: true,
    loopedSlides: 3,
    navigation: isTablet769below ? false : true,
    controller: { control: controlledSwiper },
  }
  const sliderPropstext: SwiperProps = {
    loop: true,
    loopedSlides: 3,
    slidesPerView: 'auto',
    centeredSlides: true,
    effect: 'fade',
    modules: [Controller, EffectFade, Navigation],
    controller: { control: controlledSwiper },
    allowTouchMove: false,
  }

  const generateID = (index: number) => {
    if (activeIndex === index) return 'activeSlide'
    else return 'inActiveSlide'
  }
  const type: string = firstItem?.viewtype || 'default'

  return (
    <PlainSliderContainer viewtype={type}>
      {firstItem.collectionTitle && (
        <PlainSliderTitleContainer
          bgColorType={
            placement.backgroundColor?.toLowerCase() || ''
          }
        >
          <PlainSliderTitle>
            {firstItem.collectionTitle}
          </PlainSliderTitle>
          {firstItem.collectionText && (
            <TextContainer textAlign={cmsApiService.getTeaserOverlayTextAlign('center')}>
              {HTMLReactParser(firstItem.collectionText)}</TextContainer>
          )}
        </PlainSliderTitleContainer>
      )}
      <PlainSliderWrapper viewtype={type}>
        <PlainSliderImage
          viewType={type}
          {...sliderPropsImage}
          initialSlide={initialSlide}
          watchFirstItem={isFirstItem}
          watchLastItem={isLastItem}
          bgColorType={
            placement.backgroundColor?.toLowerCase() || ''
          }
        >
          {teasers.map((teaser, index) => (
            <SwiperSlide key={`slide__${index}`}>
              <PlainSliderItem>
                <MediaOverlap
                  teaser={teaser}
                  teaserIndex={index}
                  crop={crop}
                  viewType={firstItem.viewtype}
                />
              </PlainSliderItem>
            </SwiperSlide>
          ))}
        </PlainSliderImage>
        <PlainSliderText
          {...sliderPropstext}
          viewType={type}
          bgColorType={
            placement.backgroundColor?.toLowerCase() || ''
          }
          onSwiper={setControlledSwiper}
          initialSlide={initialSlide}
          onActiveIndexChange={i => setActiveIndex(i.realIndex)}
        >
          {teasers.map((teaser, index) => (
            <SwiperSlide key={`slide__${index}`} id={generateID(index)}>
              <PlainSliderTextContainer
                backgroundColor={
                  teaserBackGroundColorToBg(placement.backgroundColor, theme)
                    .backgroundColor
                }
                bgColorType={
                  placement.backgroundColor?.toLowerCase() || ''
                }
              >
                <TextModulev2
                  teaserIndex={index}
                  teaser={teaser}
                  viewType={firstItem.viewtype}
                />
              </PlainSliderTextContainer>
            </SwiperSlide>
          ))}
        </PlainSliderText>
      </PlainSliderWrapper>
    </PlainSliderContainer>
  )
}

export default PlainSlider
