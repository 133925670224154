import React, { useContext } from 'react'
import GenericBanner from '../GenericBanner'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import styles from './styles/LandscapeBanner.module.scss'

const LandscapeBanner: React.FC = () => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  if (!context) return null

  return (
    <GenericBanner
      crop="LANDSCAPE_BANNER_M"
      className={styles.landscapeBanner}
      lazy
      termsAndConditionsClassName={styles.landscapeBannerTermsConditions}
      videoWrapperClassName={styles.landscapeBannerVideoWrapper}
    />
  )
}

export default LandscapeBanner
