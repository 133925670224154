import React from 'react'
import {
  StyledGridOfBoardsContainer,
  StyledGridOfBoardsItem,
} from './GridOfBoards.style'

import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import TextModule from '../../CmsModules/TextModule'

export interface IProps {
  columnAmount: 2 | 3 | 4
  placement: IPlacement | ICMCollection
  placementCenter?: boolean
}

const GridOfBoards: React.FC<IProps> = ({ placement, columnAmount }) => {
  const teasers =
    'items' in placement
      ? placement.items.filter(isLXTeaser)
      : placement.teasableItems.filter(isLXTeaser)

  const marginVertical = (placement as IPlacement)?.marginVertical
  const alignTop = marginVertical === 'X' && !placement?.placementCenter

  return (
    <StyledGridOfBoardsContainer alignTop={alignTop}>
      {teasers.map((teaser, index) => (
        <StyledGridOfBoardsItem key={teaser.id} columnAmount={columnAmount} backgroundColor={teaser.teaserBackground} marginVertical={marginVertical}>
          <TextModule
            teaserIndex={index}
            teaser={teaser}
            viewType={placement.viewtype}
            placementCenter={placement?.placementCenter}
            sectionTitle={false}
          />
        </StyledGridOfBoardsItem>
      ))}
    </StyledGridOfBoardsContainer>
  )
}

export default GridOfBoards
