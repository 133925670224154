import React from 'react'
import clsx from 'clsx'
import { GridContainerType } from './types'
import styles from './styles/GridContainer.module.scss'

const GridContainer: React.FC<GridContainerType> = ({ className, container, noVertPadding, children }) => {
  return (
    <div
      className={clsx(className, 'grid-container', styles.gridContainer, {
        [styles.container]: !!container,
        [styles.noVertPadding]: !!noVertPadding,
      })}
    >
      {children}
    </div>
  )
}

export default GridContainer
