import styled from '@mui/material/styles/styled'
import { StyledAnchor } from '../../UI'

export const MediaOverlapContainer = styled('div', {
  name: ' MediaOverlap',
  slot: 'Container',
})(() => ({
  position: 'relative',
  width: '100%',
  img: {
    objectFit: 'cover',
  },
}))

export const MediaOverlapFullWidthContainer = styled(MediaOverlapContainer, {
  name: ' ImageOverlap',
  slot: 'FullWidthContainer',
})(({ theme }) => ({
  span: {
    img: {
      [theme.breakpoints.up(769)]: {
        height: theme.spacing(116),
      },
      [theme.breakpoints.up(1025)]: {
        height: theme.spacing(145),
      },
      [theme.breakpoints.up(1281)]: {
        height: theme.spacing(163),
      }
    }
  }
}))

export const MediaOverlapLandscapeContainer = styled(MediaOverlapContainer, {
  name: ' ImageOverlap',
  slot: 'LandscapeContainer',
})(() => ({
  height: '100%',
  a: { height: '100%' },
}))

export const MediaOverlapTopPageContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlap',
  slot: 'TopPageContainer',
})(({ theme }) => ({
  span: {
    img: {
      [theme.breakpoints.up(0)]: {
        height: theme.spacing(71),
      },
      [theme.breakpoints.up(769)]: {
        height: theme.spacing(116),
      },
      [theme.breakpoints.up(1025)]: {
        height: theme.spacing(80),
      }
    }
  },
}))

export const MediaOverlapSquatContainer = styled(MediaOverlapContainer, {
  name: 'MediaOverlap',
  slot: 'SquatContainer',
})(({ theme }) => ({
  span: {
    img: {
      [theme.breakpoints.up(0)]: {
        height: theme.spacing(110),
      },
      [theme.breakpoints.up(769)]: {
        height: theme.spacing(60),
      },
    }
  },
}))

export const MediaOverlapAnchor = styled(StyledAnchor, {
  name: ' ImageOverlap',
  slot: 'Anchor',
})(() => ({
  display: 'block',
  '&::after': {
    backgroundColor: 'transparent',
  },
  '& > span': {
    display: 'flex !important',
  },
}))
