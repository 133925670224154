import React from 'react'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import styled from '@mui/material/styles/styled'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  background: 'transparent',
  color: 'inherit',
  boxShadow: 'none',
  padding: 0,

  '&:not(:last-child)': {
    borderBottom: theme.spacing(1),
  },

  '&:before': {
    display: 'none',
  },
}))
